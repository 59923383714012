import React , {useState,useEffect} from 'react';
import { useLoaderData } from 'react-router-dom';
import './terms.css'
// import Page from '../page';

// }

export default function Terms() {

    const [current,setCurrent] = useState(1)

    
     const toggle=(index) =>{
         console.log('login---------------->',index)
        setCurrent(index)
      }
//   const post = useLoaderData();

  return (
    <div>


    <div class="container-fluid pt-5 ">
        <div class="columns terms-body">
             <div class="column is-one-quarter">
                    <div class="box">
                        <div class="section-menu__title">Ufuon at work product resources</div>
                        <ul id="menu-kahoot-at-work-product-resources" class="section-menu-primary">

                            <li  class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-730 current_page_item menu-item-736"><a itemprop="url" onClick={()=>toggle(1)}> Terms and Conditions </a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(2)}>Enterprise Agreement</a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(3)}>Acceptable Use Policy</a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(4)}>Student Privacy Policy</a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(5)}>Cookie Policy</a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(6)}>Editorial Guidelines</a></li>
                            <li  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-854"><a itemprop="url" onClick={()=>toggle(7)}>Privacy Policy</a></li>
                        </ul>
                    </div>
            </div>
              <div class={`${
                current === 1 ? "column terms-side ": "close"
                }`}>
                <h2>Ufuon Terms and Conditions</h2>
                <h4>Welcome to Ufuon</h4>
                 <br/>
                <p>These Terms apply to your use of the Ufuon Services and our Platform, and we encourage you to read them carefully. 
                  Please also refer to the definitions set out at the bottom of this page. The Terms and any attachments related to it including our <a href='' target="_blank" rel="noopener"> Acceptable Use Policy</a>
                  Applicable guidelines and any Service Plan(s), form a legal Agreement between you and Ufuon for your use of the Ufuon Services. 
                  If you, or an Organization you are affiliated with, have entered into an Enterprise Agreement with Ufuon, your use of the Ufuon Services and Resources will be governed by the Enterprise Agreement and the documents incorporated therein.
                    </p>
                  <p>These Terms define the terms and conditions under which you are allowed to use the Ufuon Services and consume Resources. 
                    If you do not agree to these Terms, you must immediately discontinue your use of the Ufuon Services and Resources.</p>
                   <p>In addition to these Terms, our <a href= "#" onClick={()=>toggle(7)}  rel="noopener">Privacy Policy</a> and <a href= "#" target="_blank" rel="noopener"> Cookie Policy  </a> describe how we process the personal information 
                   we may collect when you use the Ufuon Services, and how we protect your privacy. Details about our processing of students'
                   personal data, which we collect as a processor or ´school official on the school or teacher's behalf, can be found in our <a href= "#" target="_blank" rel="noopener">Student's Privacy Policy.</a>
                   </p>
                   <br/>
                   <h3>Responsible use and conduct.</h3>
                      <h5>General</h5>
                      
                    <p>You agree to only use  Ufuon Services and Resources for the purposes intended and as permitted by the Agreement and
                       in accordance with applicable laws. If you use  Ufuon Services in breach of the Agreement or applicable laws, 
                       we may terminate your account or suspend your use of  Ufuon Services.</p>
                  <p>You are responsible for maintaining the confidentiality of your account and any non-public authentication
                     credentials associated with your use of Ufuon Services. You must promptly notify our customer support team about any possible misuse of your accounts, 
                     authentication credentials or any security incident related to Ufuon Services.
                         </p>
                  <p>You represent and warrant that the information you provide when registering for an account is accurate and you shall not
                     misrepresent personal information or otherwise be untruthful about your identity in connection with your use of the Services. 
                     Ufuon reserves all rights to take legal action against anyone who misrepresents personal information or is otherwise untruthful about their identity in connection with their use of the Services. 
                     Notwithstanding the preceding, you acknowledge that Ufuon cannot guarantee the accuracy of any information submitted by any user or the identity of any user who chooses to use the Services. We are not obliged to verify the identity of any user.
                   </p>
                   <br/>
                   <h5>Administrator assumption of control</h5>
                   
                   <p>
                   If you use an email address provided by an Organization you are affiliated with (e.g. an employer or a school) to order Ufuon Service, 
                   you represent that you have the authority to use that Organization’s domain to sign up for a Service Plan in your capacity as a member of that Organization. 
                  As the owner of the domain associated with your email address, the Organization may assume control over and manage your use of Ufuon Services. In such a case,
                  your Organization’s designated Administrator may (i) control and administer your account, including modifying and terminating your access and (ii) access and process your data, 
                  including the contents of your communications and files. Ufuon may inform you that your associated Organization has assumed control of Ufuon Services covered by your Service Plan, but Ufuon is under no obligation to provide such notice.
                  If your organization is administering your use of the Ufuon Services or managing the tenant associated with your Service Plan, direct your data subject requests and privacy inquiries to your administrator. If your organization is not administering 
                  your use of Ufuon Service or managing such tenants, direct your data subject requests and privacy inquiries to Ufuon by contacting us.
                   </p>
                   <br/>
                   <h5>Parents and guardians responsible to safeguard children’s safety</h5>
                   
                   <p>
                   Ufuon provides certain Services and Resources which may be used by children. Children are not regarded as fully independent 
                   users of the Services and Resources. It is the parents’/guardians’ responsibility, respectively, to ensure that the use of  
                   Ufuon Services and Resources is in accordance with the Agreement and to safeguard the children’s safety and privacy. Apart from the limitations 
                   within the functioning of the Services, Ufuon has no control over how parents/guardians oversee the children’s use of the Services as Ufuon does not 
                   interact directly with the children using the Services and Resources.
                   </p>
                   <br/>
                   <h4>Licensed rights</h4>
                  
                   <p>Ufuon Services and Resources are the property of Ufuon and Ufuon’s content licensors (as applicable). We grant you a limited, 
                    non-exclusive, revocable license to make use of Ufuon Services and the Resources (the “<b>License</b>“) in accordance with the Agreement.
                    </p>
                    <p>
                    In our Free Service Plans, the License permits are used for non-commercial purposes only. If you wish to use the Ufuon For services and Resources 
                    for professional or commercial purposes, you must have a Paid Service Plan. In any event, you are not allowed to use Ufuon for advertising, marketing or sales activities unless 
                    expressly authorised by Ufuon in an agreement or under your Service Plan. You agree to abide by our Acceptable Use Policy and any other user guidelines that apply to your use of Ufuon Services
                     and the Resources.
                    </p>
                    <p>
                    Except for the rights expressly granted to you in the Agreement, Ufuon grants no right, title, or interest to you in the Ufuon Service or Resources. For the avoidance of doubt,
                    you may not charge any fees or require any payment as part of your use of the Service or the Resources, except as expressly authorized by Ufuon.
                    </p>
                    <p>
                    Ufuon’s Brands are the sole property of Ufuon or its licensors. The Agreement does not grant you any rights to use any Ufuon. The brand is for any purpose, whether for commercial or non-commercial use.
                    </p>
                    <br/>
                    <h3>Ufuon’s Service Plans and Content Subscriptions</h3>
                    <br/>
                    <h4>Available Service Plans</h4>
                    <p>
                    Ufuon’s services are available under different Service Plans. Our Websites provide details of the available Service Plans, which can be categorized as one of the following:
                    </p>
                    <ol>
                      <li><b>Free Service Plans:</b> Our free Service Plans offer access to a limited set of features in the Ufuon Services. The free Service Plans can be used for non-commercial purposes only.
                       </li>
                       <li><b>Paid Service Plans:</b> Our paid Service Plans give access to more features and functionality. The Paid Service Plans are permitted for use in professional and commercial settings, 
                       such as training, surveys and events, both for internal and external audiences. The features and functionalities available depend on the plan type. Each plan has limitations on the number of licenses and participants.
                       </li>
                    </ol>
                    <p>
                    The Service Plans vary based on the Ufuon The service you have chosen to subscribe to and the content and features of a Service Plan may vary based on when you signed up and whether 
                    you have signed up through our Website or via the App. Details about the different Service Plans are available on our website.
                    </p>
                    <p>
                    You can manage your Service Plans from your Ufuon user account. If you have purchased a Paid Service Plan through a distribution platform such as Google Play or Apple App Store,
                     you must manage your account through such platforms.
                    </p>
                    <br/>
                    <h5>Content Subscriptions</h5>
                    <p>
                    In addition to your chosen Service Plan, you may purchase certain Content Subscriptions from the Ufuon Platform, such as Ufuon+ AccessPass.
                    </p>
                    <p>
                    A Content Subscription gives you access to a library of content that is curated at Ufuon’s sole discretion.
                    </p>
                    <p>You acknowledge that the content available through each Content Subscription is subject to change at Ufuon’s sole discretion, 
                      including without limitation the removal and addition of content or change of existing content.
                    </p>
                    <p>
                    You agree to only use the content available through Content Subscriptions in accordance with all terms and conditions applicable to Ufuon Services and Resources, 
                    including without limitation the Acceptable Use Policy. In particular, you are not entitled to change, resell, or share such content in any other way than expressly permitted herein.
                    </p>
                    <p>
                    Ufuon does not verify and cannot guarantee the accuracy, relevance, integrity, fitness for a particular purpose, availability, or quality of any content included in Content Subscriptions.
                    </p>
                    <p>You can manage your Content Subscriptions from your Ufuon user account.</p>
                    <br/>  
                    <h3>Payments, cancellations, upgrades</h3>
                    < br/>
                    <h4>General</h4>
                    <p>Paid Service Plans and Content Subscriptions can be purchased directly from Ufuon in the App or on our Website, through third parties or through an Enterprise Agreement with Ufuon.
                       This section of the Terms only applies where you have signed up for one of our Paid Service Plans or Content Subscriptions directly from us through our App or website.
                        You promise that your registration information, payment details (if you have signed up for a paid Service Plan subscription) and any other information that you submit to us are true, accurate, and complete,
                      and you agree to keep it that way at all times. If you have provided incorrect or inaccurate information, you should return to the log-in screen or correct such information in your account settings. 
                      You promise that no other person shall be allowed to use or access your account, or in any other way make use of the rights conferred to you pursuant to the Agreement.
                      </p>
                      <br/>
                      <h4>Payments and Subscription Periods.</h4>
                      <p>All Paid Service Plans and Content Subscriptions are payable in advance of each Subscription Period, non-cancelable and, except as expressly stated in an Enterprise Agreement, non-refundable.</p>
                      <p>You will be charged prior to being granted access to your Paid Service Plan or Content Subscriptions. At the end of your current Subscription Period, your Paid Service Plan and/or 
                        Content Subscriptions subscription will automatically renew and you will be charged for the next Subscription Period in accordance with your registered payment information.
                      </p>
                      <p>If you don’t pay for your Paid Service Plan on time, Ufuon reserve the right to suspend your account or remove Paid Service Plan features.</p>
                      <br/>
                      <h4>Cancellations</h4>
                      <p>If you do not wish to renew your Subscription Period for a Paid Service Plan or Content Subscription, you must cancel your Paid Service Plan and/or Content Subscriptions before
                        the end of your current Subscription Period. Any cancellation will become effective on the first day after the last day of your current Subscription Period. Upon cancellation,
                        your access to features that are exclusive to the Paid Service Plans or Content Subscriptions will cease, and your subscription will be automatically downgraded to a Free Service 
                        Plan for the Ufuon Service you subscribe to (if available).
                        </p>
                        <br/>
                        <h4>Upgrades and downgrades.</h4>
                        <p>You may at any time upgrade or downgrade your Paid Service Plan subscription to a different Paid Service Plan. If you downgrade your subscription to a lower-tier Service Plan, 
                          the downgrade will take effect on the day after the last day of the current Subscription Period. If you upgrade your subscription to a higher-tier Service Plan, the upgrade will 
                          take effect immediately subject to payment of the applicable upgrade fee.
                          </p>
                          <p>Upon upgrading, your Paid Service Plan will be subject to the new subscription term. You will be credited for any remaining term under your previous Paid Service Plan.</p>
                          <br/>
                          <h4>Price changes and new Service Plans.</h4>
                          <p>Ufuon may change the price for a Paid Service Plan and/or Content Subscription. If such a price change applies to you it will be effective from the beginning of your next
                            Subscription Period. Your continued use of the Ufuon Services constitutes your acceptance of the price change. If you do not agree with the price changes, you have the right to reject the change by 
                            cancelling your subscription prior to your next Subscription Period renewal.
                            </p>
                            <p>We do not provide any refunds if the price for a Paid Service Plan or Content Subscription drops, or if we offer subsequent promotional pricing or change the content or features of a Service Plan.</p>
                            <p>Ufuon may decide to change or replace our Service Plans. If the Service Plan you have subscribed to is changed or replaced, your subscription will automatically be for the Service Plan that corresponds
                               with the pricing of your original plan unless you chose to upgrade your subscription.
                            </p>
                            <br/>
                            <h4>Trials</h4>
                            <p>
                            We may, at our own sole discretion, offer you a Trial. We reserve the right to revoke the Trial and put your account on hold in the event that we determine that you are not eligible. Separate terms and conditions, 
                            including restrictions on available features or areas of use, may apply when using a Ufuon Service under a Trial.
                            </p>
                            <p>
                            For some Trials, we’ll require you to provide your payment details to start the Trial. In such cases, your Trial will automatically be converted to a paid subscription for the selected Service Plan and/or Content 
                            Subscription as of the first day after the end of your Trial period. We will use the payment details you provided when you started the Trial to charge you. If you do not want to continue using your selected Service 
                            Plan on a paid subscription basis, or a Content Subscription, you must cancel your subscription before the end of the Trial period.
                            </p>
                            <br/>
                            <h4>Pre-paid subscription Codes</h4>
                            <p>
                            If you have received a Code, separate terms and conditions presented to you along with the Code may also apply to your access to the Service and you agree to comply with any such terms and conditions.
                            </p>
                            <p>
                            Paid subscription Codes purchased from third parties or through other platforms (e.g. Google Play or Apple App Store) are subject to the refund policies of those platforms. Ufuon cannot be held 
                            responsible for these platforms’ policies.
                            </p>
                            <br/>
                          <h4>Taxes.</h4>
                          <p>Prices listed may not include sales or value-added tax and applicable tax may be calculated and added at the time you complete a transaction, depending on where you made the purchase, 
                            such tax may not be visible to you until you receive a receipt for your purchase. In certain jurisdictions, local laws require that prices include all applicable taxes, in which case this will be indicated at the time of purchase.
                            </p>
                            <br/>
                            <h3>User content</h3>
                            <br/>
                            <h4>Ufuon’s license to User Content.</h4>
                            <p>
                            User Content is available to you only, kept with limited visibility and only used by Ufuon for the purposes of providing the Services and Resources. We will keep all data which 
                            you submit to the Services, including any User Content, secure and only process your data for the purpose of providing Ufuon Services to you.
                            </p>
                            <p>You hereby grant Ufuon a worldwide, non-exclusive, limited-term license to access, use, process, and display User Content only as reasonably necessary:
                              (a) to provide, maintain and update the Services; (b) to prevent or address service, security, support or technical issues; (c) as required by law; and (d) as expressly permitted by you.
                            </p>
                            <p>Ufuon will delete all User Content from your account upon termination of the Agreement, except for any Public User Content licensed to 
                              Ufuon as explained herein, or otherwise in accordance with Ufuon’s Privacy Policy (as applicable).
                              </p>
                              <br/>
                              <h4>Your responsibility for User Content.</h4>
                              <p>You promise that with respect to any User Content you post on Ufuon: (1) you have all rights necessary to upload such User Content to Ufuon Service and to grant the above license 
                                to Ufuon, and (2) such User Content or its use by Ufuon does not violate the Agreement, applicable law, or the intellectual property (including without limitation copyright), publicity, 
                                personality, or other rights of others.
                              </p>
                              <p>You are solely responsible for all User Content that you publish on Ufuon, Ufuon does not endorse the correctness of the User Content or any opinion contained in any User Content.
                              </p>
                              <br/>
                              <h4>Monitoring of User Content.</h4>
                              <p>Unless agreed differently in a separate agreement with us, Ufuon may review, monitor, edit or remove User Content in our sole discretion, but is under no obligation to do so. 
                                In all cases, Ufuon reserves the right to remove or disable access to any User Content that breaches the Agreement, including breach of our <a href="">Acceptable Use Policy</a> and <a href="">Editorial Guidelines.</a>
                                Removal or disabling of access to User Content shall be at our sole discretion.
                                </p>
                                <br/>
                                <h4>Public User Content.</h4>
                                <p>If you choose to make User Content Public User Content, you hereby grant to Ufuon a perpetual (or, for as long as permitted under applicable law), non-exclusive, sub-licensable, transferable, 
                                  royalty-free, irrevocable, fully paid, universal license to commercialize, use, reproduce, make available to the public (e.g. perform or display), publish, translate, modify, create derivative works from, 
                                  and distribute your Public User Content through any medium, whether alone or in combination with other content or materials, in any manner and by any means, method or technology, whether now known or hereafter created. 
                                  Aside from the rights specifically granted herein, you retain ownership of all rights, including intellectual property rights, in the User Content.
                                </p>
                                <br/>
                                <h3>Verified creators on Ufuon’s Marketplace</h3>
                                <br/>
                                <h4>Verified Creators</h4>
                                <p>The Ufuon Marketplace allows a Verified Creator to offer Marketplace Content for sale to other Ufuon users. </p>
                                <h4>Publication of the Marketplace Content.</h4>
                                <p>Marketplace Content is created and submitted to Marketplace online by Verified Creators for the 
                                  fee determined by the Verified Creator in accordance with the Clause below. Publication of any Marketplace 
                                  Content is subject to Ufuon’s review and approval at Ufuon’s sole discretion. 
                                </p>
                                <p>Once submitted, Ufuon is granted a license to the Marketplace Content to the extent necessary to display, 
                                  reproduce and otherwise use the Marketplace Content in question in order to make it available to Ufuon users on the Marketplace. 
                                </p>
                                <p>Marketplace Content purchased by users is available to such users for one (1) year following the date of purchase.</p>
                                <br/>
                                <h4>Marketplace Content Requirements.</h4>
                                <p>Verified Creators represent and warrant that all Marketplace Content complies with this Agreement, 
                                  including without limitation any applicable Ufuon guidelines for content, the <a href="">Ufuon Acceptable Use Policy </a> and the <a href="">Editorial Guidelines,</a> 
                                  and Verified Creators have the necessary rights to submit the Marketplace Content on the Marketplace. 
                                </p>
                                <p>Verified Creators control the availability of their submitted Marketplace Content and are free to remove 
                                  it from the Marketplace at any time. However, users who have already purchased Marketplace Content maintain 
                                  access throughout their applicable subscription period.
                                </p>
                                <p>Ufuon reserves the right to remove any Marketplace Content in violation of the applicable law, 
                                  third-party intellectual property rights, or this Agreement, including without limitation the applicable 
                                  Ufuon guidelines for content. </p>
                                <p>Ufuon will promote, market and highlight the submitted Marketplace Content at its sole discretion.</p>
                                <br/>
                                <h4>Prices and Payout Rates.</h4>
                                <p>The price points, payout rates and other commercial terms applicable to Verified Creators 
                                  and purchasers of Marketplace Content are further detailed <a href=''>here.</a></p>
                                <p>Verified Creators shall choose the preferred price points for users for their Marketplace 
                                  Content as part of the submission process for the Marketplace Content in question. Marketplace 
                                  Content may also be offered for free. The price points available depend on the Verified Creator’s 
                                  Service Plan.</p>
                                <p>Verified Creators will be entitled to a payout rate depending on their Service Plan for each sale of 
                                  Marketplace Content, payable at the end of each payout period. The payout rates may be adjusted upon prior 
                                  written notice from Ufuon, and the adjusted rates will apply from the beginning of the next payout period.</p>
                                  <p>Verified Creators who submit Marketplace Content in violation of this Agreement will not be entitled to a payout.</p>
                                 <br/>
                                 <h4>Ufuon's Option to Purchase Marketplace Content</h4>
                                 <p>Ufuon may offer to purchase a license to selected Marketplace Content from Verified Creators for a fixed, one-time payment. 
                                  In the event that the amount offered by Ufuon is accepted by the Verified Creator in writing, you hereby grant Ufuon a perpetual, 
                                  irrevocable, worldwide and royalty-free license to use the selected Marketplace Content for Ufuon’s own purposes, including without 
                                  limitation incorporating the Marketplace Content into other Ufuon offerings such as the Ufuon’s + AccessPass, and to promote, sell or 
                                  bundle the Marketplace Content with other Ufuon offerings. </p>
                                  <p>For the avoidance of doubt, in the event that Ufuon is granted a license to Marketplace Content pursuant to this Clause, the Verified 
                                    Creator will still be entitled to a payout for sales to users of the Marketplace Content in question. </p>
                                    <br/>
                              <h4>Payment</h4>
                              <p>Verified Creators shall set up an account with a third-party payment provider as part of the onboarding process, and Ufuon will facilitate payment
                                 of all payouts through such third-party payment providers. Verified Creators are solely responsible for ensuring that their user information, including 
                                 the information pertaining to the payment provider in question, is correct and up to date.</p>
                              <br/>
                              <h4>Changes to Service Plans</h4>
                              <p>In the event that a Verified Creator changes to a Free Service Plan,  the Verified Creator will no longer be able to offer Marketplace Content for sale on 
                                the Marketplace and any Marketplace Content already submitted will be removed. Users who have purchased Marketplace Content from the Verified Creator, will 
                                maintain access to such Marketplace Content until the end of their subscription period. </p>
                              <p>In the event that a Verified Creator makes any other changes to its Service Plan or other factors that are relevant in determining the right to payout in 
                                accordance with these Terms, such changes shall take effect from the beginning of the next payout period. </p>
                                <br/>
                            <h4>Third-Party Applications</h4>
                            <p>Ufuon’s Service is integrated with Third Party Applications that make available content, products, and/or services to you. Use of these Third Party Applications is optional. 
                              The Third Party Applications may have their own terms and conditions and privacy policies, which you agree to be bound by when you choose to use these Third Party Applications, 
                              including but not limited to YouTube: https://www.youtube.com/t/terms.</p>
                              <br/>
                            <h4>Data protection</h4>
                            <p>The Data Processing Agreement located <a href=''>here</a> shall govern with respect to the protection of personal data processed in connection with this Agreement.</p>
                            <br/>
                            <h4>Term and Termination</h4>
                            <p>The Agreement will continue to apply for as long as you use a free or paid Ufuon account. Ufuon may terminate the Agreement or suspend your access to Ufuon Services at any 
                              time, including in the event of your actual or suspected unauthorised use of the Ufuon Service or Resources, or non-compliance with the Agreement. If you or Ufuon terminate 
                              the Agreement, or if Ufuon suspends your access to the Ufuon Service in accordance with the Agreement, you agree that Ufuon shall have no liability or responsibility to you 
                              and Ufuon will not refund any amounts that you have already paid, to the fullest extent permitted under applicable law.</p>
                            <p>Any License granted to you on the Ufuon Services and Resources not already terminated by the expiry of your Service Plan shall be terminated as of the termination or expiry
                               of the Agreement.</p>
                            <p>You hereby acknowledge and agree that the perpetual license granted to Ufuon by you will continue after the expiry or termination of any of the Agreements for any reason. </p>
                            <p>Sections of the Agreement that, either explicitly or by their nature, must remain in effect even after termination of the Agreement, shall survive termination.</p>
                            <br/>
                            <h4>Warranty and Disclaimer</h4>
                            <p>We can assure you that the Services will perform materially in accordance with the description of the Ufuon Services and Resources as set out on our Websites. 
                              For all Service plans, we will use commercially reasonable efforts to make the Services available 24 hours a day, 7 days a week, excluding planned downtime. 
                              We will notify you in advance of any planned downtime expected to result in significant downtime. In the event of any breach of Ufuon’s obligations in this Section 11, 
                              Ufuon shall at its own expense (i) use reasonable endeavours to rectify such non-compliance, and if rectification is not reasonably possible (ii) replace all the 
                              non-conforming parts of the Services and Resources. Ufuon disclaims any other warranties than those set out in the Agreement.  Further,  under no circumstances will 
                              Ufuon be liable for any Third Party Applications, including the failure of any such Third Party Applications.</p>
                            <p>Ufuon shall not be responsible for a breach of these warranties if caused by:</p>
                            <ol>
                              <li>Hardware, software or other components which are not part of the Services and Resources;</li>
                              <li>Errors or problems caused by or contributed to by hardware, software or other components which 
                                are not provided by Ufuon, including any modifications by you or third parties;</li>
                              <li>Errors or problems caused by you not complying with this Agreement;</li>
                              <li>if you have rejected the implementation of any upgrade, change, hot fix, or similar, 
                                which would have prevented the error or problem;</li>
                                <li> And errors or problems caused by third parties not acting on behalf of Ufuon, 
                                  including in respect of changes to Services and Resources.</li>
                            </ol>
                            <br/>
                            <h4>Limitation of Liability</h4>
                            <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL UFUON, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, 
                              ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; OR (2) ANY LOSS OF USE, DATA, 
                              BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR INABILITY TO USE THE UFUON SERVICE, THIRD-PARTY APPLICATIONS, OR THIRD-PARTY 
                              APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY, WITHOUT REGARD TO WHETHER UFUON HAS BEEN MADE AWARE OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF 
                              ITS ESSENTIAL PURPOSE. UFUON’S AGGREGATE LIABILITY FOR ALL CLAIMS ARISING UNDER OR IN CONNECTION WITH THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNTS PAID BY YOU TO UFUON! 
                              UNDER THIS AGREEMENT DURING THE TWELVE MONTHS IMMEDIATELY PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY.</p>
                            <p>NOTHING IN THE AGREEMENT REMOVES OR LIMITS UFUON’S LIABILITY FOR FRAUD, FRAUDULENT MISREPRESENTATION, DEATH OR PERSONAL INJURY CAUSED BY ITS NEGLIGENCE, AND, IF REQUIRED 
                              BY APPLICABLE LAW, GROSS NEGLIGENCE.</p>
                            <br/>
                            <h4>Apple-specific Terms</h4>
                            <p>In addition to the sections above, and notwithstanding anything to the contrary in Terms, this section applies with respect to your use of any version of our app compatible 
                              with the iOS operating system of Apple. Apple is not a party to these Terms and does not own and is not responsible for the app. Apple is not providing any warranty for the 
                              app except, if applicable, to refund the purchase price for it. Apple is not responsible for maintenance or other support services for the app and shall not be liable for any 
                              other claims, losses, liabilities, damages, costs or expenses with respect to the app, including any third-party product liability claims, claims that the app fails to conform 
                              to any applicable legal or regulatory requirement, claims to arise under consumer protection or similar legislation, and claims with respect to intellectual property infringement. 
                              Any inquiries or complaints relating to the use of the app, including those pertaining to intellectual property rights, must be directed to Ufuon. The license you have been granted 
                              in these Terms is limited to a non-transferable license to use the app on an Apple-branded product that runs Apple’s iOS operating system and is owned or controlled by you, or as 
                              otherwise permitted by the Usage Rules set out in Apple’s App Store Terms of Service. In addition, you must comply with the terms of any third-party agreement applicable to you when 
                              using the App, such as your wireless data service agreement. Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms and, upon your acceptance of the terms and 
                              conditions of these Terms, will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary of these Terms. 
                              Notwithstanding these rights of Apple and Apple’s subsidiaries, Ufuon’s right to enter into, rescind or terminate any variation, waiver or settlement under these Terms is not 
                              subject to the consent of any third party.</p>
                            <br/>
                            <h4>Indemnification</h4>
                            <p>To the fullest extent permitted by applicable law, you agree to indemnify and hold harmless Ufuon and its parent company and affiliates, and their directors, officers, managers, employees, 
                              donors, agents, and licensors, from and against any claims and all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from (1) Your breach of the Agreement; 
                              (2) any User Content; (3) any activity in that You or anyone using your account engages on or through the Ufuon Service; and (4) Your violation of any law or the rights of a third party. 
                              We reserve the right to take over the exclusive defence of any claim for which we are entitled to indemnification under these Terms. In such event, you shall provide us with such cooperation 
                              as is reasonably requested by us.</p>
                            <br/>
                            <h3>Miscellaneous</h3>
                            <h5>Entire agreement</h5>
                            <p>Other than as stated in this section or as explicitly agreed upon in writing between you and Ufuon, the Agreement constitutes all the terms and conditions agreed upon between you and Ufuon 
                              and supersedes any prior agreements in relation to the subject matter of this Agreement, whether written or oral.</p>
                            <br/>
                            <h5>Severability and Waiver</h5>
                            <p>Unless as otherwise stated in the Agreement, should any provision of the Agreement be held invalid or unenforceable for any reason or to any extent, such invalidity or unenforceability 
                              shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreement, and the application of that provision shall be enforced to the extent permitted by law.</p>
                            <p>Any failure by Ufuon or any third-party beneficiary to enforce the Agreement or any provision thereof shall not waive Ufuon’s or the applicable third-party beneficiary’s right to do so.</p>
                            <br/>
                            <h5>Assignment</h5>
                            <p>Ufuon may assign the Agreement or any part of them, and Ufuon may delegate any of its obligations under the Agreement. You may not assign the Agreement or any part of them, nor transfer or 
                              sub-license your rights under the Agreement, to any third party.</p>
                              <br/>
                            <h5>Changes to the Terms.</h5>
                            <p>We reserve the right to change these Terms from time to time. Changes may for example be made to accommodate new products or services, to adapt to legal requirements, or otherwise to better adapt the 
                              Terms to our business. If there is a material change to these Terms, we will notify you either by email to your registered email account, in-App or in-Service notifications or on our official Websites 
                              (as appropriate). It is your responsibility to read any such notice carefully. Your continued use of the Services after such changes will constitute acknowledgement and agreement of the modified Terms. 
                              You may cancel your Service Plan or terminate the Agreement if you do not wish to continue using the Service under the new version of the Terms. We reserve the right to change the Acceptable Use Policy 
                              at any time without notice.</p>
                            <br/>
                            <h5>Governing Law / Jurisdiction</h5>
                            <p>The Agreement is governed by the statutes and laws of Nigeria without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods. Nigerian law will apply to 
                              all matters relating to the use of the Services and the purchase of any products or services through the Services.</p>
                            <p>Furthermore, any action to enforce the Agreement shall be brought in the courts located in Rivers State, Nigeria. you hereby agree to the jurisdiction of such courts and waive any jurisdictional, 
                              venue, or inconvenient forum objections to such courts.</p>
                            <h4>Definitions</h4>
                            <p><b>“Acceptable Use Policy”</b> means the Ufuon Acceptable Use Policy in force from time to time and accessible <a href=''>here.</a></p>
                            <p><b>“Administrator”</b> means your Organization’s designated administrator of your Ufuon account, if applicable.</p>
                            <p><b>“Agreement”</b> is the collective term that encompasses these Terms, the Acceptable Use Policy, any Service Plan and, if applicable, the Enterprise Agreement or Content Subscription.</p>
                            <p><b>“App”</b> means Ufuon’s software/mobile applications.</p>
                            <p><b>“Apple”</b> means Apple Inc.</p>
                            <p><b>“Children”</b> means Nigerian children under age 6 and children outside Nigeria under age 16.</p>
                            <p><b>“Code”</b> means a code or other offer provided or sold by or on behalf of Ufuon for access to a paid Service Plan subscription and/or Content Subscription.</p>
                            <p><b>“Content Subscriptions”</b> means the various libraries of premium content available for purchase on a subscription basis in addition to the Service Plan chosen by each user.</p>
                            <p><b>“Enterprise Agreement”</b> means a separate agreement entered into by Ufuon and your associated Organization.</p>
                            <p><b>“Ufuon+ AccessPass”</b> means the Content Subscription that features certain content from the Academy part of the Ufuon Platform.</p>
                            <p><b>“Ufuon”</b> means Ufuon ASA.</p>
                            <p><b>“Ufuon Brands”</b> means all Ufuon trademarks, service marks, trade names, logos, domain names, and any other features of the Ufuon brand.</p>
                            <p><b>“License”</b> means the license granted to you pursuant to section 3 of these Terms.</p>
                            <p><b>“Marketplace”</b> means Ufuon’s online marketplace for Ufuon content.</p>
                            <p><b>“Marketplace Content”</b> means selected Ufuon content for sale to other users.</p>
                            <p><b>“Organization”</b> means an organization you are affiliated with, such as your employer.</p>
                            <p><b>“Platform”</b> means Ufuon´s Websites and Apps where the Ufuon Services are available.</p>
                            <p><b>“Public User Content”</b> means, as some Ufuon Services are based on User Content and rely on users contributing content to the Service, 
                            the Content whereby the User, on their own choice and by actively toggling the “publish” button, makes certain User Content, such as
                             Ufuon games/quizzes, available to the general public.</p>
                          <p><b>“Resources”</b>  means the information, resources, services, products, and tools provided for you in the Ufuon Services.</p>
                          <p><b>“Service Plan”</b> means the various subscription plans for which Ufuon Services are available.</p>
                          <p><b>“Services”</b> means the Ufuon services, including any software, that you subscribe to under these Terms.</p>
                          <p><b>“Subscription Period”</b> means the duration of the applicable Service Plan and/or Content Subscription.</p>
                          <p><b>“Terms”</b>  means these general terms and conditions applicable to your use of Ufuon Services.</p>
                          <p><b>“Third Party Applications”</b> means websites and services delivered by third parties that are integrated into the Services and 
                          Resources in order to make certain features, content, products, and/or services available to you.</p>
                          <p><b>“Trial”</b> means trials of paid Service Plan subscriptions or Content Subscriptions for a specified period without payment or at a discounted rate.</p>
                          <p><b>“User Content”</b>  means the content and data which Users upload to the Service, including without limitation quizzes, pictures, videos, text, messages, 
                          information, user feedback, and any other content.</p>
                          <p><b>“Verified Creator”</b> means a Ufuon user with a Paid Service Plan who has been verified in accordance with Kahoot!’s verification process found <a href=''>here.</a></p>
                          <p><b>“Website(s)”</b> means Ufuon websites Ufuon.com</p>
                        











                             
                                            


</div>        

            <div class={` ${
                current === 2 ? "column terms-side " : "close"
                }`}>
                <h2>Ufuon Enterprise Agreement.</h2>
                 <br/>
                  <p>This Ufuon Enterprise Agreement (“<b>EA</b>”) between Customer and Ufuon governs the provision of the Ufuon Services and Resources to which Customer has subscribed pursuant to an Order Form signed by Customer referencing this EA. This EA, 
                    including any appendices or annexes hereto, together with the Order Form(s) and any addenda or annexes thereto, shall be collectively referred to as the “<b>Agreement</b>”. In the event of any inconsistency or conflict between the terms of 
                    this EA and the terms of any Order Form, the terms of the Order Form shall control. If Customer obtains access to products or services from one of Ufuon’s group companies under Customer’s Agreement with Ufuon, Customer’s use of such 
                    services or products is governed by the applicable group company’s terms and conditions.
                  </p>
                  <br/>
                  <h3>1. Licensed Rights</h3>
                  <p>The Services and the Resources are the property of Ufuon and Ufuon’s content licensors, as applicable.  We hereby grant You the License to use the Services and Resources in accordance with the terms of this Agreement, including the applicable 
                    Order Form(s), the Service Plan to which you have subscribed and Ufuon’s Acceptable Use Policy. </p>
                  <p>Access to and use of the Services is restricted to the specified number of Seat Licenses permitted under Your Service Plan, subject to any upgrades. You acknowledge and agree that each User account is personal and may only be used and accessed 
                    by the specified User. However, You may reassign a Seat License to a new User replacing a previous User who no longer requires ongoing use of the Services. Users may stream the functionalities (e.g. games, multiple-choice quizzes etc.) to the 
                    maximum number of participants as decided by Ufuon at any time.</p>
                  <p>All rights not expressly granted by Ufuon to You are expressly and unconditionally reserved by Ufuon and its content licensors (as applicable) and may not be implied by or inferred from any provision of this Agreement or by the conduct of the Parties.  
                    For the avoidance of doubt, this Agreement does not grant You any rights to use any Ufuon Brands for any purpose, whether for commercial or non-commercial use.</p>
                  <p>If You send us ideas or feedback about our Services, You agree that we may freely use or reference those ideas and feedback and do not owe You any payment or have any other obligation of any kind for such ideas or feedback.</p>
                  <br/>
                  <h3>2. Use of the Services</h3>
                  <p>You are responsible for Your Users’ use of the Services and Resources and shall ensure that all Users adhere to this Agreement at all times. </p>
                  <p>You may appoint Users as Administrators. Administrators may access, disclose, restrict, or remove User Content in or from Users’ accounts, and monitor, 
                    restrict or terminate access to Users’ accounts. </p>
                  <p>Ufuon may suspend any use of Ufuon Services, or remove or disable any User account or User Content that Ufuon reasonably and in good faith believes violates this Agreement. Ufuon will use commercially reasonable efforts to notify You prior to any such suspension 
                    or disablement unless Ufuon reasonably believes that: (a) it is prohibited from doing so under applicable law or under legal process (such as government administrative agency processes or court orders); or (b) it is necessary to delay notice in order to prevent imminent 
                    harm to the Ufuon Services or a third party. Under circumstances where notice is delayed, Ufuon will provide notice if and when the related restrictions in the previous sentence no longer apply. </p>
                  <br/>
                  <h3>3. Customer’s Data and User Content</h3>
                  <p>User Content is available to You and Your Administrators and Users only. We will keep all data that You and your Users submit to the Services secure and only process Your data for the purpose of providing the Services to You, at all times in compliance with the Data Processing Agreement.</p>
                  <p>Subject to the terms of the Agreement, You grant Ufuon a worldwide, non-exclusive, limited-term license to access, use, process, and display User Content only: (a) as reasonably necessary to provide, maintain and update the Services; (b) as reasonably necessary to prevent 
                    or address service, security, support or technical issues; (c) as required by law or as permitted by the Data Processing Agreement; and (d) as expressly permitted by You in a separate agreement with us. You represent and warrant that (y) all rights necessary to grant the rights hereunder 
                    to Ufuon have been obtained and (z) User Content will not violate the Agreement, applicable law, or the rights of third parties.  Ufuon reserves the right to remove or disable access to any User Content, including any Public User Content, that breaches our Acceptable Use Policy, the Agreement 
                    or applicable law, without prior notification to Customer or any third party. </p>
                  <p>Ufuon will delete all User Content from You or Your Users upon the termination of the Agreement, except for any Public User Content pursuant to Section 4 below, and otherwise in accordance with Ufuon’s Privacy Policy or the Data Processing Agreement (as applicable).</p>
                  <br/>
                  <h3>4. Public User Content</h3>
                  <p>Some of the functionality may permit You to make User Content available to the public via the Services. If You choose, in your sole and absolute discretion, to make User Content available to the general public by actively adjusting the visibility settings in the Ufuon Services, thereby 
                    turning such content into Public User Content, You hereby grant to Ufuon a perpetual (or, for as long as permitted under applicable law), non-exclusive, sub-licensable, transferable, royalty-free, irrevocable, fully paid, universal license to commercialize, use, reproduce, make available 
                    to the public (e.g., perform or display), publish, translate, modify, create derivative works from, and distribute your Public User Content (including the user name of the User having generated the Public User Content) through any medium, whether alone or in combination with other content 
                    or materials, in any manner and by any means, method or technology, whether now known or hereafter created. Aside from the rights specifically granted herein, You retain ownership of all rights, including intellectual property rights, in the Public User Content.</p>
                  <br/>
                  <h3>5. Third-Party Applications</h3>
                  <p>The Services are integrated with Third-Party Applications that make available content, products, and/or other services to You. Use of Third-Party Applications is optional. If You choose to enable any of these Third-Party Applications, Your use of such Third-Party Applications will be governed 
                    by and subject to the respective terms and conditions and privacy policies of each such Third-Party Application (including, but not limited to, the YouTube terms of service located at https://www.youtube.com/t/terms).  You understand and agree that Ufuon does not endorse any such Third-Party 
                    Application, nor shall Ufuon be liable for any Third-Party Applications under any circumstances, including the failure of any such Third-Party Applications. </p>
                    <br/>
                  <h3>6. Account Security</h3>
                  <p>You are responsible for maintaining control over the devices and accounts that are used to access the Service and Resources and the confidentiality of passwords and any payment details associated with Your accounts. You are responsible for updating and maintaining the accuracy of the information 
                    You provide to us relating to Your accounts. You are also responsible for preventing unauthorized access and use of Your account by anyone other than Your Users. Ufuon can suspend or terminate an account in order to protect You, Ufuon or our partners from fraudulent activity.  </p>
                    <br/>
                 <h3>7. Fees and Payment</h3>
                 <p>You will pay all Fees set forth on an Order Form.  All payments will be made in the currency specified on the Order Form unless otherwise agreed to by the Parties in writing. If You provide the credit card information to Ufuon, You authorize Ufuon to charge a such credit card for all Services and 
                  Seat Licenses, as applicable, listed in the Order Form for the initial Subscription Period and any renewal Subscription Period(s). Such charges shall be made in advance, either annually or in accordance with any different billing frequency stated in the applicable Order Form. If the Order Form specifies 
                  that payment will be by a method other than a credit card, Ufuon will invoice You in advance and otherwise in accordance with the relevant Order Form. Unless otherwise stated in the Order Form, invoiced fees are due net 30 days from the invoice date. You are responsible for providing complete and accurate 
                  billing and contact information to Ufuon and notifying Ufuon of any changes to such information. In addition to any other remedies available, Ufuon may suspend Services in the event of payment delinquency.  Fees are exclusive of all local, state, federal or foreign taxes, VAT, levies or duties of any nature. 
                  You are responsible for payment of all taxes due to a governmental authority, if any, except for taxes imposed on Ufuon’s net income. You shall provide to Ufuon any certificate of exemption or similar document required to exempt any transaction under an Order Form from sales tax or other tax liability.</p>
              <br/>
              <h3>8. Upgrades</h3>
              <p>If You choose to upgrade Your Service Plan or purchase additional Seat Licenses or other options as may be available during a Subscription Period, any incremental fees associated with such upgrades will be prorated over the remaining period of the then-current Subscription Period, billed to Your account 
                and due and payable upon implementation of such upgrades. In any future Subscription Periods, the fees billed to You will reflect any such upgrades.</p>
              <br/>
              <h3>9. Publicity</h3>
              <p>Neither Party shall refer to the identity of the other Party in promotional material, publications, press releases or other forms of publicity relating to the Ufuon Services unless the prior written consent of the other Party has been obtained, provided, however, that Ufuon may use Your name and logo 
                for the limited purpose of identifying You as a customer of the Ufuon Services.</p>
              <br/>
            <h3>10. Confidential Information</h3>
            <p>Each Party will protect the other Party’s Confidential Information from unauthorized use, access or disclosure in the same manner as each Party protects its own Confidential Information, but with no less than reasonable care. Except as otherwise expressly permitted pursuant to this Agreement, each Party 
              may use the other Party’s Confidential Information solely to exercise its respective rights and perform its respective obligations under this Agreement and shall disclose such Confidential Information (a) solely to the employees and/or non-employee service providers and contractors who have a need to know 
              such Confidential Information and who are bound by terms of confidentiality intended to prevent the misuse of such Confidential Information; (b) as necessary to comply with an order or subpoena of any administrative agency or court of competent jurisdiction; or (c) as reasonably necessary to comply with 
              any applicable law or regulation. </p>
            <br/>
            <h3>11. Term and Auto-Renewal</h3>
            <p>Your subscription(s) shall commence on the date specified on the Order Form and continue until the earlier of (a) expiration or non-renewal of all Subscription Periods, or (b) termination of this Agreement as more fully set forth below. Each Order Form will automatically renew for successive periods 
              equal in length to the expiring Subscription Period unless one of the Parties gives written notice of termination at least thirty (30) days prior to the expiration of the then-current Subscription Period. The fee for the renewal Subscription Period will be equal to the then-current fee unless Ufuon 
              has given You at least thirty (30) days prior written notice of a fee increase, which shall be effective upon renewal.  The term of this EA and this Agreement shall continue as long as an Order Form referencing this EA remains valid and in effect. </p>
            <br/>
            <h3>12. Termination</h3>
            <p>Either Party may terminate the Agreement, including any applicable Order Form(s), if: (a) the other Party materially breaches the Agreement and fails to cure that breach within thirty (30) days after receipt of written notice, or (b) the other Party ceases its business operations or becomes subject 
              to insolvency proceedings and the proceedings are not dismissed within ninety (90) days.  However, Ufuon may immediately terminate this Agreement for cause and without advance notice if You violate Ufuon’s Acceptable Use Policy. </p>
            <p>If You terminate the Agreement in accordance with this Section due to Ufuon’s uncured material breach, Ufuon will refund You any prepaid fees covering the remainder of the Subscription Period as of the effective date of termination.</p>
            <p>If Ufuon terminates this Agreement for Your material breach in accordance with this Section, Ufuon will not refund any amounts that You have already paid and You will be required to pay Ufuon any unpaid fees covering the remainder of the Subscription Period pursuant to all applicable Order Forms.  
              In no event will termination relieve You of your obligation to pay any fees due or payable to Ufuon for the period prior to the effective date of termination.</p>
            <p>Upon termination, Your right to use the Services and Resources provided by Ufuon will immediately cease, and Ufuon reserves the right to remove or delete any information that You may have on file with Ufuon, including any account or login information.</p>
            <p>If applicable, You hereby acknowledge and agree that the perpetual license granted to Ufuon by You in relation to Public User Content will continue after the expiry or termination of the Agreement for any reason.</p>
            <p>Sections of the Agreement that, either explicitly or by their nature, must remain in effect even after termination of the Agreements, shall survive termination.</p>
            <br/>
            <h3>13. Warranties</h3>
            <p>We warrant that the Services will perform materially in accordance with the description of the Ufuon Services and Resources as set out on our Websites and this Agreement. For all Service plans, we will use commercially reasonable efforts to make the Services available 24 hours a day, 7 days a week, 
              (a) excluding planned downtime and (b) Force Majeure Events. We will notify You in advance of any planned downtime which is expected to result in significant downtime. In the event of any breach of Ufuon’s obligations in this Section 13, Ufuon shall, at its own expense (y) use reasonable endeavours to
              rectify such non-compliance, and if rectification is not reasonably possible (z) replace all the non-conforming parts of the Services and Resources. Ufuon expressly disclaims all warranties of any kind other than those set out in this Agreement.  </p>
            <p>Ufuon shall not be responsible for a breach of these warranties if caused by: (a) hardware, software or other components which are not part of the Services and Resources; (b) errors or problems caused by or contributed to by hardware, software or other components which are not provided by Ufuon, including
               any modifications by You or third parties; (c) Your rejection of the implementation of any upgrade, change, hot fix, or similar, which would have prevented the error or problem; (d) errors or problems caused by third parties not acting on behalf of Ufuon, including in respect of changes to Services and Resources; 
               and (e) Your failure to fulfil Your obligations pursuant to this Agreement. If You report an issue to Ufuon’s support services/help desk and Ufuon, after due investigation, determines that such an issue was covered by one of the above exclusions, You shall reimburse Ufuon for Ufuon’s time and resources allocated 
               toward addressing the issue.</p>
            <br/>
            <h3>14. Ufuon’s Indemnification</h3>
            <p>Ufuon will indemnify, defend and hold You harmless from and against all liabilities, damages and costs (including settlement costs and reasonable attorneys’ fees) arising out of any claim by a third party that Your use of the Services infringes the intellectual property rights of a third party. In no event will Ufuon
               have any obligations or liability under this section arising from; (a) the use of any Services or Resources in a modified form or in combination with materials not furnished by Ufuon or (b) any content, information or data provided by You, Users or other third parties.</p>
               <br/>
             <h3>15. Customer’s Indemnification</h3>  
             <p>To the fullest extent permitted by applicable law, You agree to indemnify and hold Ufuon harmless from and against all claims, damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of: (a) Your breach of the Agreement; (b) any User Content; (c) any activity in which You 
              or Your Users engage in via the Services; and (d) Your violation of any law or the rights of a third party. We reserve the right to take over the exclusive defence of any claim for which we are entitled to indemnification under the Agreement. In such event, You shall provide us with such cooperation as
               is reasonably requested by us.</p>
              <br/>
              <h3>16. Limitation of Liability</h3>
              <p>***To the maximum extent permitted by law, in no event will Ufuon, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for any indirect, special, incidental, punitive, exemplary, or consequential damages in connection with this Agreement, 
                regardless of legal theory, without regard to whether Ufuon has been made aware of the possibility of those damages, and even if a remedy fails of its essential purpose. </p>
              <p>Ufuon’s aggregate liability for all claims arising under or in connection with this Agreement shall be limited to the amounts paid by you to Ufuon under this Agreement during the twelve (12) months immediately preceding the last event giving rise to liability.</p>
              <p>Nothing in the Agreement removes or limits Ufuon’s liability for fraud, fraudulent misrepresentation, death or personal injury caused by its negligence, and, if required by applicable law, gross negligence.***</p>
              <br/>
              <h3>17. Changes to the Services and Service Plans</h3>
              <p>Ufuon reserves the right to update and modify the Service Plans and the Services and the Resources from time to time by, for example, adding additional features to a Service Plan. Ufuon may automatically migrate You to a new Service Plan for administrative purposes whereby Your access is the same as under the original Service Plan, 
                but with potentially additional features. Any such changes to Services, Resources or Service Plans will not reduce the quality of the Services or negatively impact the use of the Services and Resources, or negatively impact your rights and our obligations during the term of this Agreement.</p>
              <br/>
              <h3>18. Apple-Specific Terms</h3>
              <p>In addition to the sections above, and notwithstanding anything to the contrary set forth herein, this section applies with respect to your use of any version of our application, or “app”, compatible with the iOS operating system of Apple Inc. (“Apple”). Apple is not a party to this Agreement and does not own and is not responsible 
                for the app. Apple is not providing any warranty for the app except, if applicable, to refund the purchase price for it. Apple is not responsible for maintenance or other support services for the app and shall not be responsible for any other claims, losses, liabilities, damages, costs or expenses with respect to the app, including 
                any third-party product liability claims, claims that the app fails to conform to any applicable legal or regulatory requirement, claims to arise under consumer protection or similar legislation, and claims with respect to intellectual property infringement. Any inquiries or complaints relating to the use of the app, including those 
                pertaining to intellectual property rights, must be directed to Ufuon The license you have been granted in this Agreement is limited to a non-transferable license to use the app on an Apple-branded product that runs Apple’s iOS operating system and is owned or controlled by you, or as otherwise permitted by the Usage Rules set out in 
                Apple’s App Store Terms of Service. In addition, you must comply with the terms of any third-party agreement applicable to you when using the App, such as your wireless data service agreement. Apple and Apple’s subsidiaries are third-party beneficiaries of this Agreement and, upon your acceptance of the terms and conditions of this Agreement, 
                will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary of this Agreement. Notwithstanding these rights of Apple and Apple’s subsidiaries, Ufuon’s right to enter into, rescind or terminate any variation, waiver or settlement under this Agreement is not subject to 
                the consent of any third party.</p>
                <br/>
               <h3>19. Severability and Waiver</h3> 
               <p>Unless as otherwise stated in the Agreement, should any provision of the Agreement be held invalid or unenforceable for any reason or to any extent, such invalidity or enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreement, and the application of that provision shall be 
                enforced to the extent permitted by law.  Any failure by Ufuon or any third-party beneficiary to enforce the Agreement or any provision thereof shall not waive Ufuon’s or the applicable third-party beneficiary’s right to do so.</p>
              <br/>
              <h3>20. Governing Law and Venue</h3>
              <p>This Agreement shall be governed by the laws of Nigeria, without regard to the conflict of laws or the United Nations Convention on the International Sales of Goods.  Furthermore, any action to enforce the Agreement shall be brought in the courts located in Rivers State, Nigeria. You hereby agree to personal jurisdiction by such courts and waive
                 any jurisdictional, venue, or inconvenient forum objections to such courts.</p>
              <br/>
              <h3>21. Assignment</h3>
              <p>Ufuon may assign this Agreement or any part hereof, and delegate any of its obligations under the Agreement. You may not assign the Agreement or any part thereof, nor transfer or sub-license Your rights under the Agreement, to any third party.</p>
              <br/>
              <h3>22. Amendments</h3>
              <p>This Agreement constitutes the entire agreement between the parties and supersedes any prior or contemporaneous agreements in relation to the subject matter of the Agreement, whether written or oral.  This Agreement shall take precedence over any: (a) conflicting terms and conditions received from one of the Parties; and (b) conflicting terms and 
                conditions found in other agreements issued by the Parties that interfere with the subject matters regulated by the Agreement, including but not limited to any purchase order or other order documentation You provide (all such terms or conditions being null and void). Except as otherwise set forth herein, any amendments or variations to the Agreement 
                shall be made in writing and shall be duly authorized by representatives of both Parties.  </p>
                <br/>
              <h3>24. Definitions</h3>
              <p><b>“Acceptable Use Policy”</b>means Ufuon’s acceptable use policy available at https://ufuon.com/terms/acceptable-use-policy/.</p>
              <p><b>“Administrator”</b>means a Customer-designated User who administers the Services on behalf of Customers Users.</p>
              <p><b>“Confidential Information” </b>means all information disclosed by one Party to the other Party which is in tangible form and designated as confidential or is information, regardless of form, which a reasonable person would understand to be confidential given the nature of the information and circumstances of disclosure, including, but not limited to, 
              the terms of this Agreement and Customer Personal Data. Notwithstanding the foregoing, Confidential Information shall not include information that (a) was already known to the receiving Party at the time of disclosure by the disclosing Party; (b) was or is obtained by the receiving Party from a third party not known by the receiving Party to be under an 
              obligation of confidentiality with respect to such information; (c) is or becomes generally available to the public other than by violation of this Agreement or another valid agreement between the Parties; or (d) was or is independently developed by the receiving Party without the use of the disclosing Party’s Confidential Information.</p>
              <p><b>“Controller” </b>shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“Customer”</b> or <b>“You”</b>means the entity purchasing the Ufuon Services and Resources pursuant to an Order Form referencing this EA.</p>
              <p><b>“Customer Personal Data”</b>means all Personal Data that Ufuon processes on behalf of the Customer.</p>
              <p><b>“Data Processing Agreement”</b> or <b>“DPA”</b> means Ufuon’s standard Data Processing Agreement available at https://Ufuon.com/terms/dpa/, which regulates the Parties' obligations under applicable data protection law.</p>
              <p><b>“Data Protection Law”</b> means the GDPR, and the applicable Nigerian law implementing the GDPR.</p>
              <p><b>“Data Subject”</b> shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“DPA”</b> shall have the same meaning as “Data Processing Agreement”.</p>
              <p><b>“EU Data”</b> means Customer Personal Data protected by EU Data Protection Laws.</p>
              <p><b>“Fee(s)”</b> means the fee(s) payable by the Customer for its Service Plan as set out in the Order Form.</p>
              <p><b>“Force Majeure Event”</b> means any circumstances beyond Ufuon’s reasonable control, including, but not limited to, an act of God, governmental action, flood, fire, earthquake, civil unrest, an act of terror, strike, 
              Internet service provider failure or delay, and denial-of-service attacks.</p>
              <p><b>“GDPR”</b>means Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.</p>
              <p><b>“Ufuon”</b>means Ufuon ASA acting as the provider of the Services and Resources under this Agreement.</p>
              <p><b>“Ufuon Brands”</b> means all Ufuon! trademarks, service marks, trade names, logos, domain names, and any other features of the Ufuon brand.</p>
              <p><b>“License”</b> means the worldwide, limited, non-exclusive, non-transferable, non-sublicensable and revocable license to make use of Ufuon Services for commercial, educational and/or entertainment use of the Service granted to You pursuant to this Agreement’s 
              Section 1 and as further detailed in the Order Form. </p>
              <p><b>“New Sub-Processor”</b>means any Sub-Processors engaged by Ufuon after the effective date of the Agreement.</p>
              <p><b>“Order Form”</b> means the ordering document setting forth the subscription to the Service Plan selected by the Customer. </p>
              <p><b></b></p>
              <p><b>“Party”</b> or <b>“Parties”</b> means Ufuon and the Customer as parties to this Agreement.</p>
              <p><b>“Personal Data”</b> shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“Personal Data Breach”</b> shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“Processor”</b> shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“Public User Content”</b> means User Content made available to the general public, at the User’s sole and absolute discretion, by actively adjusting the visibility settings of such User Content.</p>
              <p><b>“SCC”</b> means the European Commission’s standard contractual clauses for data transfers between EU and non-EU countries.</p>
              <p><b>“Seat License(s)”</b> means the number of specifically named individual user seat licenses the Customer has acquired for the respective Services.</p>
              <p><b>“Service(s)”</b> or <b>“Ufuon Service(s)”</b> means the SaaS services provided by Ufuon and subscribed to by the Customer under this Agreement and as set out in the Order Form.</p>
              <p><b>“Service Plan”</b> means the service plan applicable to the Services to which Customer has subscribed as set out in Order Form.</p>
              <p><b>“Sub-Processor”</b>  means an entity to which Ufuon subcontracts its processing of the Customer's Personal Data.</p>
              <p><b>“Subscription Period(s)”</b> means the period for which the Customer is granted a license to use the Ufuon Services as agreed by the Parties in the Order Form.</p>
              <p><b>“Supervisory Authority”</b> shall have the meaning provided to such term pursuant to Data Protection Law.</p>
              <p><b>“Third-Party Application(s)” </b> means the third-party applications, websites and services that are integrated into the Services and Resources in order to make certain features, content, products and/or services available to You.</p>
              <p><b>“Resource(s)”</b> means the information, resources, services, products, and tools provided, either directly or indirectly, by Ufuon to the Customer as part of this Agreement (including any other content and materials available on Ufuon.com 
              as well as text, graphics, website name, code, images and logos).</p>
              <p><b>“User”</b> means an individual who has access to a Ufuon-enabled Service account on behalf of the Customer.</p>
              <p><b>“User Content”</b> means the content and data that Users upload to the Service, including without limitation quizzes, pictures, videos, text, messages, information, user feedback and any other content.</p>
              <p><b>“Website(s)”</b> means Ufuon website Ufuon.com.</p>



</div>
       <div class={`${
                current === 3 ? "column terms-side ": "close"
                }`}>
                <h2>Acceptable Use Policy</h2>
                <h4>Acceptable use and content</h4>
                 <br/>
                <p>You agree to use Ufuon Services and Resources only for the purposes intended as permitted by the Agreement, including the Terms, this Acceptable Use Policy and applicable laws and regulations 
                  (“<b>Acceptable Use</b>”). When using Ufuon Services and Resources, you agree and understand that the:
                    </p>
                  <ol>
                    <li>In order to access our Services and Resources, you may be required to provide certain information about yourself (such as identification, contact details, etc.) as part of the registration 
                      process, or as part of your ability to use the Services and Resources. You agree that any information you provide will always be accurate, correct, and up to date.</li>
                    <li>You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Services and Resources. Accordingly, 
                      you are responsible for all activities that occur under your account/s.</li>
                    <li>Accessing (or attempting to access) any of our Services and Resources by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access 
                      (or attempt to access) any of our Services and Resources through any automated, unethical or unconventional means.</li>
                    <li>Engaging in any activity that disrupts or interferes with our Services and Resources, including the servers and/or networks to which our Services and Resources are located or connected, 
                      is strictly prohibited.</li>
                    <li>Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources is strictly prohibited.</li>
                    <li>You are not allowed to post content or use our Services and Resources in a way which includes or involves commercial or sales activities, such as advertising, promotions, contests, or 
                      sweepstakes unless this is expressly permitted under your agreement with Ufuon;</li>
                    <li>We may provide various open communication tools on our Services, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various 
                      social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means that if you choose to 
                      use these tools to submit any type of content to our Services, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise 
                      using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that breaches the Acceptable Use Policy or the Agreement, including;</li>
                  </ol>
                 <ol type="I">
                  <li>You are not allowed to engage, post any content or register and/or use a user name which includes material that is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, 
                    invasive, racist, or contains any type of suggestive, inappropriate, or explicit language which;</li>
                  <li>Infringes on any trademark, patent, trade secret, copyright, or another proprietary right of any party;</li>
                  <li>Contains any type of unauthorized or unsolicited advertising;</li>
                  <li>Impersonates any person or entity, including any Ufuon employees or representatives.</li>
                 </ol>
              <p>Our <a href=''>Editorial Guideline</a> describe Ufuon’s content policies in more detail and content we consider to be illegal or unacceptable to our platform. Contributors to our platform shall read, understand and 
              respect our Editorial Guidelines and not publish content that breaches the Editorial Guidelines and this Acceptable Use Policy.</p>
              <p>We have the right at our sole discretion to remove any content that we feel in our judgment does not comply with your agreements with us, along with any content that we feel is otherwise offensive, harmful, objectionable, 
                inaccurate, or violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, 
                and consent to waive any claim against us.</p>
              <p>Our <a href=""> Safety Guide</a> gives an insight into how Ufuon works with platform safety and the measures we implement to keep our platform safe, including how we moderate the content.</p>
              <br/>
            <h4>Content reporting and DMCA</h4>
            <p>If you believe that any content does not comply with our standards we invite you to report this to us. Information about how you can report user content is provided in our
              <a href=''>Editorial Guideline</a> and <a href=''> Safety Guide</a> You can also reach out to us at <a href="">Ufuon.com.</a>
            </p>
            <p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under Nigerian. copyright law. If you believe in 
              good faith that material available in the Service infringes your copyright, you (or your agent) may send to Ufuon a written notice by mail or e-mail, requesting that Ufuon removes such material or block access to it. If you 
              believe in good faith that someone has wrongly filed a notice of copyright infringement against you, the DMCA permits you to send to Ufuon a counter-notice. Notices and counter-notices must meet the then-current statutory 
              requirements imposed by the DMCA.See <a href='http://www.copyright.gov/'>http://www.copyright.gov/</a> for details. Notices and counter-notices must be sent in writing to Ufuon by mail to <a href="">legal@Ufuon.com.</a>
              We suggest that you consult your legal advisor before filing a DMCA notice or counter-notice. Please note that Ufuon may, in appropriate circumstances, terminate or suspend users and account holders who are repeat infringers.
              </p>
            <p>Notwithstanding the foregoing, Ufuon may in its sole discretion take such actions as it deems fit in order to protect the intellectual property rights of any intellectual property owner, including but not limited to
              removing user content.</p>


</div>   

<div class={`${
                current === 4 ? "column terms-side ": "close"
                }`}>
                <h2>Ufuon Student Privacy Policy</h2>
                <br/>
              <h3>1. Overview</h3>
                 <br/>
                <p>Ufuon is dedicated to making learning awesome and we take the privacy of our users seriously. Keeping students’ personal information 
                  safe is a priority at Ufuon. This Student Privacy Policy explains how we use the personal information we collect from and about students 
                  when they engage with Ufuon in the classroom through the products that are offered to schools and teachers in Ufuon EDU and our individual 
                  plans for teachers (the “School Services”). As set forth below, we use such personal information solely as a service provider or “data 
                  processor” to our school and school district customers.
                    </p>
                  <p>To learn about our practices with respect to other users, including teachers who sign up for Ufuon and student accountholders 
                    who use Ufuon outside of the school environment, please read our <a href="">Privacy Policy.</a></p>
                    <br/>
                    <h3>2. Our handling of student information</h3>
                    <br/>
                    <p>As a trusted provider of tools that help teachers and schools make learning awesome for students, Ufuon is particularly concerned 
                      about the use of students’ personal information. We only request the personal information of students that we need to provide the 
                      School Services, and we comply with privacy laws, such as the Family Educational Rights and Privacy Act (“FERPA”) and the Children’s 
                      Online Privacy Protection Act (“COPPA”), as applicable.</p>
                    <p>Ufuon does not collect, retain, use or share students’ personal information, except as necessary for authorized school purposes, at 
                      the direction of our school and school district customers. Without limitation, this means that:</p>
                    <ul>
                      <li>We do not sell students’ personal information.</li>
                      <li>We do not serve targeted ads on our platforms and do not use the information we collect to serve targeted ads on other services. </li>
                      <li>Ufuon does not use or disclose information collected through our services for any targeted advertising purposes.</li>
                      <li>We do not build a profile of a student other than in support of authorized school purposes.</li>
                      <li>We do not retain students’ personal information beyond the time period required to support authorized school purposes.</li>
                    </ul>
                    <p>We support our school customers in providing parents and eligible students with access to their personal information and in fulfilling 
                      other requests that parents and eligible students may make, such as requesting the deletion of students’ personal information or refusing 
                      its further collection or use. Ufuon maintains a comprehensive program designed to protect the security, privacy, confidentiality and integrity 
                      of students’ personal information.</p>
                    <br/>
                    <h3>3. Students’ Personal information</h3>
                    <h4>3.1 Personal information we collect from students</h4>
                    <p><b>Student players:</b> Ufuon collects only a nickname, which is anonymous and not linked to a persistent identifier, from a student who merely 
                      plays a Ufuon game. We use the nickname to permit the student to play.</p>
                    <p><b>Personal information collected at a teacher’s direction:</b> Certain features of the School Services permit a teacher to collect information about the class, 
                      as well as about individual students, such as attendance, results, and answers submitted when playing Ufuon games (including in response to open-ended questions) 
                      and performance on Ufuon games. We use such information only to provide services to the teacher, such as to permit the teacher to interact with students and track 
                      their progress over time. A school or teacher may permit students to share information with others, such as with other students in the same class, school, or school district.</p>
                    <br/>
                    <h4>3.2 Information collected automatically</h4>
                    <br/>
                    <p>No personal information is collected automatically from students who engage with Ufuon through the School Services. Product usage data that is collected by Ufuon from the School 
                      Services is always anonymized or aggregated and cannot be linked to individual students.</p>
                    <br/>
                    <h4>3.3 Anonymized information</h4>
                    <br/>
                    <p>We may anonymize and/or aggregate personal information so that it no longer identifies any student. We may use and disclose such anonymized and/or aggregated information for any purpose, 
                      including for analytics purposes, to help us understand how our products are used and improve upon them.</p>
                      <br/>
                    <h3>4. Security</h3>
                    <br/>
                    <p>Ufuon maintains a comprehensive security program designed to protect the security, privacy, confidentiality and integrity of Personal Information within our organization. We have in place appropriate and 
                      reasonable technical and organizational measures designed to protect Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks involved 
                      in the processing and the nature of the Personal Information. Our security measures include data encryption, firewalls, data use, access limitations for our personnel and service providers, and physical access 
                      controls to our facilities. Our service providers that process payment data, maintain the applicable Payment Card Industry (PCI) compliance levels.</p>
                    <br/>
                    <h3>5. Compliance with FERPA and COPPA</h3>
                    <br/>
                    <p>We comply with FERPA and COPPA when applicable to us. When FERPA applies, we act as a ”school official” to our school or school district customer, which means that we are under the direct control of the 
                      school or district and use students’ personal information only to provide our services to the school or district and not for our own purposes. To the extent COPPA applies, the school or district provides 
                      us with any necessary consent on behalf of students’ parents or guardians to permit the use of Ufuon in the classroom.</p>
                      <br/>
                      <h3>6. Changes to this Policy</h3>
                      <br/>
                      <p>We may change this Student Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the version date located at the top of this Student Privacy Policy. 
                        We encourage you to review this Student Privacy Policy often to stay informed of changes that may affect you.</p>
                      <p>If we make material changes in the Privacy Policy, for example, if we seek to use personal information in a materially different way than we had previously, we will provide prior notice by, for example, 
                        email or a pop-up so that you have sufficient time to evaluate the change in practice. Of course, you can always opt-out by deleting your account if you do not accept the change.</p>

              
</div>

<div class={`${
                current === 5 ? "column terms-side ": "close"
                }`}>
                <h2>Cookie Notice</h2>
                <br/>
              <h3>1. Introduction</h3>
                <p>This Cookie Notice is prepared by Ufuon AS (<b>“Ufuon”</b> or <b>“We”</b>) and contains information about our the use of cookies we use in our products and on our websites. 
                For example, we use cookies to distinguish you from other users of our website and to provide functionality and a better user experience to you. This Cookie Notice includes 
                details about the types of cookies we use, the purpose of our use of cookies and your rights in connection with our use of cookies.
                </p>     
              <br/>
              <h3>2. What are cookies?</h3>
              <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. Cookies contain information that is transferred to your computer’s 
                hard drive. Pixels, web beacons and other technologies are also used to monitor your use of our website. In this notice, we will refer to all of the aforementioned 
                technologies as “cookies”.</p>
              <br/>
              <h3>3. How you can control your cookies</h3>
              <p>You have the right to decide whether to reject or accept cookies. Except for the essential cookies that are strictly required for our provision of services to you, cookies are only 
                permitted if you are informed about and have consented to the use of cookies. There are different ways in which you can manage your cookies:</p>
              <ul>
                <li><h4>Website cookie preference tool:</h4> A cookie banner will appear on your screen the first time when you visit our website, or access the website with a new device, informing you of 
                  our use of cookies. The cookie banner includes a link to our cookie settings preference center.</li>
                <li><h4>Browser controls:</h4>  You may set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies you may still use our websites. However, your access to 
                  some functionality and areas may be restricted or your user experience will be impaired. How you can refuse cookies through your web browser may vary from browser to browser 
                  and you should visit your browser’s help menu for more information.</li>
                <li><h4>Do Not Track:</h4> Some browsers – like Internet Explorer, Firefox and Safari – include the ability to transmit “Do Not Track” or “DNT” signals. Since uniform standards for DNT signals have not 
                  yet been adopted our web pages do not currently process or respond to DNT. Ufuon does take privacy and your right to make choices seriously and we continue to monitor developments around DNT 
                  browser technologies and the implementation of a standard.</li>
              </ul>
                <br/>
              <h3>4. Third-party websites and plug-ins</h3>
              <p>The Services and Websites include social media features, such as social media plug-ins with Twitter, LinkedIn, Instagram, TikTok and Facebook. These features may collect information about your IP 
                address and which page you are visiting on our Websites, and they may set a cookie to make sure the feature functions properly. Social media features and widgets are either hosted by a third party or 
                hosted directly on our Website. We also maintain a presence on social media platforms, including Facebook, Twitter, and Instagram. Any information, communications, or materials you submit to us via a 
                social media platform is done at your own risk without any expectation of privacy. We cannot control the actions of other users of these platforms or the actions of the platforms themselves. Your interactions 
                with those features and platforms are governed by the privacy policies of the companies that provide them.</p>
                <br/>
                <h3>5. Updates and contact</h3>
                <p>We may update this Cookie Notice from time to time to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. We encourage you to revisit this Cookie Notice 
                  regularly to stay informed about our use of cookies and related technologies.</p>
                <p>If you have questions regarding our use of cookies or this Cookie Notice, please contact us at Ufuon.com.</p>

</div>
<div class={`${
                current === 6 ? "column terms-side ": "close"
                }`}>
                <h2>Editorial Guidelines</h2>
                <br/>
              <h3>Introduction</h3>
                <p>Ufuon is committed to protecting our users and providing a safe and engaging learning environment. These Editorial Guidelines are designed to maintain the integrity of 
                  our platform and help us prevent content that is harmful or illegal.
                </p> 
                <p>Ufuon is used by millions of people around the world as a tool for fun and engaging learning – including students and children. As part of our mission to <b>make learning awesome</b>,
                we at Ufuon work hard to provide a safe platform, high quality – and of course fun!</p>    
                <p>The Editorial Guidelines apply to all contributors to the Ufuon platform. Ufuon reserves the right to remove the content in breach of our policies and to suspend users that do not 
                  respect these Editorial Guidelines.</p>
              <br/>
                <h3>Our values</h3>
              <p>Ufuon values the creativity and free expression of our users and contributors. At the same time, we recognize how important it is for Ufuon to be a place for <b>facts</b> , <b>safety</b> , <b>privacy </b>
              and <b> dignity</b> We are driven by the aspiration to allow learner to unlock their full learning potential in a fun and engaging way.</p>
              <p><i>The values underpinning Ufuon’s community standards, rules and policies</i></p>
              <ul>
                <li><h4>Factual</h4> We want to make sure the content people are seeing on Ufuon is grounded in facts.</li>
                <li><h4>Safety</h4> We strongly believe that a safe place free from violence, hate speech and intimidation empowers the learner and their learning experience.</li>
                <li><h4>Privacy</h4> Ufuon is committed to protecting personal information and privacy.</li>
                <li><h4>Dignity</h4> We believe that all people are equal in dignity and rights. We expect that people will respect the dignity of others and not harass or degrade others.</li>
              </ul>
                <br/>
            <h3>Inappropriate content</h3>
            <p>Following our values of facts, safety, privacy and dignity, Ufuon does not allow inappropriate content on our platform. Below is a list of the content that is illegal or unacceptable and that is not 
              permitted on our platform. As a contributor you are obliged to read, understand and respect these guidelines and not publish content that is any of the below:</p>
              <br/>
            <h3>Violence and Criminal Behavior</h3>
            <p><i>We do not allow content that contains or encourages violence or criminal behaviour. This includes Ufuon games (“Ufuons”) that contain, promote or endorse:</i></p>
            <ul style={{listStyleType:"square"}}>
                <li>Violence and Incitement</li>
                <li>Regulated Goods</li>
                <li>Fraud and Deception</li>
                <li>Dangerous Individuals or Organizations</li>
                <li>Terrorism or terrorist acts.</li>
            </ul>
             <br/>
             <h3>Safety</h3>
             <p><i>We do not allow content that may threaten the safety of our users, or that may encourage unsafe or harmful behaviour. This includes Ufuons that contain or are likely to trigger:</i></p>
             <ul style={{listStyleType:"square"}}>
                  <li>Child Sexual Exploitation, Abuse and Nudity</li>
                  <li>Sexual Exploitation of Adults</li>
                  <li>Bullying and Harassment</li>
                  <li>Human Exploitation</li>
                  <li>Suicide and Self-Injury</li>
                  <li>Privacy Violations and Image Privacy Rights.</li>
             </ul>
             <br/>
             <h3>Objectionable Content</h3>
             <p><i>We do not allow content that is illegal, discriminating or inappropriate or objectionable. This includes Ufuons that involve:</i></p>
             <ul style={{listStyleType: "square"}}>
                <li>Discrimination or racism</li>
                <li>Cruelty and Insensitivity</li>
                <li>Hate Speech</li>
                <li>Copyright Infringement</li>
                <li>Illegal use of third-party intellectual property, work, ideas or any other unauthorized use of content belonging to others.</li>
                <li>Misappropriation of a person’s name, image or likeness for commercial purposes.</li>
             </ul>
             <br/>
             <h3>Integrity and Authenticity</h3>
             <p><i>We do not accept content or behaviour that threatens the integrity and authenticity of our platform, our Ufuons and our verified educators. 
              This includes that we do not accept;</i></p>
              <ul style={{listStyleType:"Square"}}>
                  <li>Misrepresentation</li>
                  <li>Spam</li>
                  <li>Cybersecurity</li>
                  <li>Inauthentic Behavior</li>
                  <li>Manipulated Media</li>
                  <li>Memorialization</li>
                  <li>COVID-19 Misinformation</li>
                  <li>Intellectual Property Infringement.</li>
              </ul>
              <br/>
              <h3>Commercial content</h3>
              <p><i>The Ufuon platform shall not be used to post advertisements or promote goods or services. Our platform is meant for engaging learning and is not a marketing channel. 
                Therefore, when you publish content on Ufuon you shall respect the following;</i></p>
              <ul style={{listStyleType:"square"}}>
                  <li>We do not allow sales activities, such as advertising, promotions, contests, or sweepstakes on our platform unless this is expressly authorized by 
                    Ufuon in an agreement or your specific service plan.</li>
                  <li>You are not allowed to use Ufuon to promote your own or third-parties goods or services unless this is expressly authorized by Ufuon in an agreement or 
                    your specific service plan.</li>
                  <li>Except for the limited license outlined in your agreement, we do not allow the exploitation of our intellectual property unless this is expressly authorized by Ufuon.</li>
              </ul>
              <br/>
              <h3>Moderation</h3>
              <p>Ufuon has a growing team of trained moderators whose bespoke role is to filter out inappropriate content. We use automated filter software to help block or quarantine content, 
                which is then sent to our moderators for review. The software operates on a comprehensive set of keywords, which are words that are often used with ill intent such as sexist or 
                racist expressions. We continuously work to improve this list of keywords to reflect the ever-expanding nuances of language.</p>
              <p>In addition to our filters and moderators, Ufuon needs help from our vast community of users to keep the platform safe. Automated filters are never perfect and our moderator team, 
                despite their efforts, will not be able to scan all the content that gets published on Ufuon every day. We rely on our users to respect our rules and guidelines, and to report illegal 
                or inappropriate content that is posted on our platform. If you come across content that is illegal, inappropriate or in other ways in breach of Ufuon’s values or guidelines, please 
                report this to us immediately. Our guide on how to flag content can be found <a href="">here</a>. Content that is flagged by our users will be sent to our moderator team for review</p>
              <br/>
            <h3>Enforcement</h3>
            <p>Ufuon reserves the right to remove any content that breaches these Editorial Guidelines <a href=''>terms and conditions</a> or <a href=''> acceptable use policy.</a> Content that 
            does not meet our standards may be reported and quarantined, and eventually removed from the platform.</p>
            <p>We also reserve the right to suspend or remove contributors, including verified users, whose profiles do not comply with our values or that contain content in breach of our Editorial Guidelines. 
              The consequences for violating our Editorial Guidelines vary depending on the severity of the violation. Consequences range from a single Ufuon being taken down or made private, to banning an 
              account or suspending users.</p>
              <p><i><b>We thank you for respecting and following these guidelines.</b></i></p>
            
</div>
<div class={`${
                current === 7 ? "column terms-side ": "close"
                }`}>
                <h2>Ufuon Privacy Policy</h2>
                <br/>
              <h3>1. The Basics</h3>
              <br/>
              <h4>About this Privacy Policy</h4>
                <p>Ufuon is committed to protecting the privacy and security of our users and being transparent about how we process information about you. This Privacy Policy describes how we process your Personal Information and how you can exercise your privacy rights. 
                  Capitalized terms have the meaning given to them in Annex A “Definitions”.
                </p> 
                <p>Ufuon’s services allow our users to create and upload content, play and host games and invite others to join a game. Ufuon acts as a data processor for Personal Information in user content, and for Personal Information that we may otherwise collect and 
                  process on behalf of our Users on the platform. Such processing is not covered by this Privacy Policy</p>
                <p>Please note that to the extent we provide you with notice of different or additional privacy policies, those policies will govern such interactions. For details about Ufuon’s processing of Student Data please read our
                  <a href=''> Student Privacy Policy.</a> Please see below for details regarding our collection of information from Children.
                </p>
                <br/>
                <h4>About Us,</h4>
                <p>Ufuon is a global educational technology and software-as-a-service group providing a learning platform for the global educational technology market. We focus on developing a comprehensive offering of engaging learning tools for enterprises, the educational 
                  sector as well as personal users.  Ufuon is set to make life better for both users and content creators, via our platform, you get paid while using the platform to teach, to Educate children and adults alike, more so teachers in schools can make extra 
                  income from our app teaching or book sales.</p>
                <br/>
                <h3>2. Personal Information we process</h3>
                <br/>
                <p>The Personal Information we process depends on the context of your interactions with Ufuon, your Ufuon account settings, the products and features you use, your location, and applicable law. However, the Personal Information we collect broadly falls into the 
                  following categories:</p>
                <br/>




</div>             
           </div>
       </div>
     {
      //  <!-- Testimonial End -->
      }

      {
    //<!-- Footer Start -->
      }
      <div
      class="container-fluid text-white mt-5 py-5 px-sm-3 px-md-5"
      style={{backgroundColor:"#46178F"}}
    >
      <div class="row pt-5">
        <div class="col-lg-3 col-md-6 mb-5">
          <a  href="" class="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
            style={{fontSize: "40px", lineHeight: "40px"}}
          >
            <i class="flaticon-043-teddy-bear"></i>
            <span class="text-white">ABOUT US</span>
          </a>
          <p style={{fontSize:"12px"}}>
          Ufuon is a game based learning platform that helps students
           and teachers be the best they can be. For teachers, ufuon 
           helps connect them to thousand of students who can enjoy 
           the learning content they create that  they might not have 
           access to in a  traditional classroom.  
           For students, ufuon provide an interesting way for them to learn, 
           learning on ufuon is optimized to mimic game playing, to keep the 
           interest of the students longer than a class based learning.
          </p>
          <div class="d-flex justify-content-start mt-4">
          <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0"
          style={{width: "38px", height: "38px"}} target="_blank"
          href="https://x.com/ufuon_edu"
          ><i class="fab fa-twitter"></i></a>
            <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                style={{width: "38px", height: "38px"}}
                href="https://www.facebook.com/Ufuon1-111894188419377"
                ><i class="fab fa-facebook-f"></i
            ></a>
            <a  class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                style={{width: "38px", height: "38px"}}
                href="#"
                ><i class="fab fa-linkedin-in"></i
            ></a>
            <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                style={{width: "38px", height: "38px"}}
                href="https://www.instagram.com/ufuon_edu/"
                ><i class="fab fa-instagram"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-5"  style={{paddingTop:'1%'}}>
        <h3   class=" mb-4" style={{marginLeft:'10%',color:'#fff'}}>Quick Links</h3>
        <div  class="d-flex flex-column justify-content-start" style={{marginLeft:'15%'}}>
          <a class="text-white mb-2" href="/"
            ><i class="fa fa-angle-right mr-2"></i>Home</a>
          
            <a href="https://store.ufuon.com" target={'_blank'} class="text-white mb-2"> <i class="fa fa-angle-right mr-2"></i> Ufuon store</a>

          <a class="text-white mb-2" href="/about"
            ><i class="fa fa-angle-right mr-2"></i>About Us</a>
          <a class="text-white" href="/contact"
            ><i class="fa fa-angle-right mr-2"></i>Contact Us</a>

            <a class="text-white" style={{marginTop:'2%'}} href="https://blog.ufuon.com/"
            ><i class="fa fa-angle-right mr-2"></i>Blog Page </a>
        </div>
      </div>
       
      
        <div class="col-lg-3 col-md-6 mb-5">
          <h3 class=" mb-4" style={{color:'#fff'}}>Contact us</h3>
          <b>Do you have questions or in need of further clarification? Speak to a Counsellor.</b>
           <img src="https://cdn4.iconfinder.com/data/icons/people-avatars-8/256/PEOPLE_ICON-21-512.png" style={{position:'relative',bottom:'10%'}} />
        </div>

        <div class="col-lg-3 col-md-6 mb-5">
        <h3 class="text-primary mb-4">Get In Touch</h3>
        <div class="d-flex">
          <h4 class="fa fa-map-marker-alt text-primary"></h4>
          <div class="pl-3">
            <h5 class="text-white">Address</h5>
            <p>59C Old Aba Road, Rumuobiakani, Port Harcort, Rivers State Nigeria.</p>
          </div>
        </div>
        <div class="d-flex">
          <h4 class="fa fa-envelope text-primary"></h4>
          <div class="pl-3">
            <h5 class="text-white">Email</h5>
            <p>hello.ufuon.com@gmail.com</p>
          </div>
        </div>
        <div class="d-flex">
          <h4 class="fa fa-phone-alt text-primary"></h4>
          <div class="pl-3">
            <h5 class="text-white">Phone</h5>
            <p>+234 8139 582 152</p>
          </div>
        </div>
      </div>
      </div>
      <div
        class="container-fluid pt-5"
        style={{borderTop: "1px solid rgba(23, 162, 184, 0.2)"}}
      >
        <p class="m-0 text-center text-white">
          &copy;
          <a class="font-weight-bold" style={{color:'white'}} href="#">Ufuon</a>.
          All Rights Reserved.

        {
            //<!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
        }
          Designed by 
          <a class=" font-weight-bold"style={{color:"white", marginLeft:'5px'}} href="https://codepally.com"
            >Codepally</a>
          
         
      
        </p>
      </div>
    </div>

      
      {
      // <!-- Footer End -->
      }
   
   </div>
  );
}
