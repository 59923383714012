import React from 'react';
import { useLoaderData } from 'react-router-dom';
import './about.css'
// import Page from '../page';

// }

export default function About() {

//   const post = useLoaderData();

  return (
    <div>

 {
     ///  <!-- About Start -->
 }
   
    <div class="container-fluid py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <img
              class="img-fluid rounded mb-5 mb-lg-0"
              src="https://res.cloudinary.com/codepally/image/upload/v1685544372/UFUON%20LOGO%20PNG/Layer_2_dvfddz.png"
              alt=""
            />
          </div>
          <div class="col-lg-7">
            <p class="section-title pr-5">
              <span class="pr-2">Learn About Us</span>
            </p>
            <h1 class="mb-4">Best School App For Your Kids</h1>
            <p>
            Ufuon is a company set to make life better for both teachers and learners. 
            Ufuon App is Built to aid students learning on the go. Student can Do Assignments, 
            take Quiz, Read Different  Books including fun books like comics. 
            Ufuon is designed so parents can also track their child's progress.  
            Teachers use the platform to teach, create books and quizzes through 
            which they can generate income. Ufuon also gives teachers an opportunity 
            to get hired by parents for home lessons.
                    </p>
            <div class="row pt-2 pb-4">
              <div class="col-6 col-md-4">
                <img class="img-fluid rounded" src="https://res.cloudinary.com/codepally/image/upload/v1675168751/UFUON%20LOGO%20PNG/new_image_befht5.png" alt="" />
              </div>
              <div class="col-6 col-md-8">
                <ul class="list-inline m-0">
                  <li class="py-2 border-top border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Teaching and Learning Made easy
                  </li>
                  <li class="py-2 border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Have Access to all your Educative Material in one place.
                  </li>
                  <li class="py-2 border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Mobile Class, access your School Material/Lesson from anywhere.
                  </li>
                </ul>
              </div>
            </div>
            <a href="" class="btn btn-primary mt-2 py-2 px-4">Download Now</a>
          </div>
        </div>
      </div>
    </div>



    {
        /// <!-- Facilities Start -->
    }
   
    <div class="container-fluid pt-5">
      <div class="container pb-3">
        <div class="row">
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>Create and PLay</h4>
                <p class="m-0">
                   Make your own learning content on Ufuon! Create or choose from ready-to-play 
                Ufuon contents and games  created by Verified creators and content partners 
                on the Discover page....
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-022-drum h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>Set Quiz For Your Children</h4>
                <p class="m-0">
                Easily set quizzes for students to help them 
                test and practice what they have been learning 
                in school....
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-030-crayons h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>MANAGEMENT</h4>
                <p class="m-0">
                Manage efficiently. 
                Manage the fees, attendance, assignments and various other details 
                in one place. Store everything easily and efficiently....
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-017-toy-car h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>MARKETING</h4>
                <p class="m-0">
                Market your content in one tap You can easily upload
                your course or book and sell it across all over the
                 world and earn income from your home....
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-025-sandwich h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>EASY COMMUNICATION</h4>
                <p class="m-0">
                Communicate Easily With No Interruptions. 
                Communicate with your students on the live 
                platform or chat with a particular student 
                in the chat box....
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-1">
            <div
              class="d-flex bg-light shadow-sm border-top rounded mb-4"
              style={{padding: "30px"}}
            >
              <i
                class="flaticon-047-backpack h1 font-weight-normal text-primary mb-3"
              ></i>
              <div class="pl-4">
                <h4>UFUON at SCHOOL</h4>
                <p class="m-0">
                Engaging group and distance learning for teachers and students....
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>9
    </div>
    {
        // <!-- Facilities Start -->
    }
   

   
  

 
      {
    //<!-- Footer Start -->
      }
     
      <div
        class="container-fluid text-white mt-5 py-5 px-sm-3 px-md-5"
        style={{backgroundColor:"#46178F"}}
      >
        <div class="row pt-5">
          <div class="col-lg-3 col-md-6 mb-5">
            <a  href="" class="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
              style={{fontSize: "40px", lineHeight: "40px"}}
            >
              <i class="flaticon-043-teddy-bear"></i>
              <span class="text-white">ABOUT US</span>
            </a>
            <p style={{fontSize:"12px"}}>
            Ufuon is a game based learning platform that helps students
             and teachers be the best they can be. For teachers, ufuon 
             helps connect them to thousand of students who can enjoy 
             the learning content they create that  they might not have 
             access to in a  traditional classroom.  
             For students, ufuon provide an interesting way for them to learn, 
             learning on ufuon is optimized to mimic game playing, to keep the 
             interest of the students longer than a class based learning.
            </p>
            <div class="d-flex justify-content-start mt-4">
            <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0"
            style={{width: "38px", height: "38px"}} target="_blank"
            href="https://x.com/ufuon_edu"
            ><i class="fab fa-twitter"></i></a>
              <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="https://www.facebook.com/Ufuon1-111894188419377"
                  ><i class="fab fa-facebook-f"></i
              ></a>
              <a  class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="#"
                  ><i class="fab fa-linkedin-in"></i
              ></a>
              <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="https://www.instagram.com/ufuon_edu/"
                  ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-5"  style={{paddingTop:'1%'}}>
          <h3   class=" mb-4" style={{marginLeft:'10%',color:'#fff'}}>Quick Links</h3>
          <div  class="d-flex flex-column justify-content-start" style={{marginLeft:'15%'}}>
            <a class="text-white mb-2" href="/"
              ><i class="fa fa-angle-right mr-2"></i>Home</a>
            
              <a href="https://store.ufuon.com" target={'_blank'} class="text-white mb-2"> <i class="fa fa-angle-right mr-2"></i> Ufuon store</a>

            <a class="text-white mb-2" href="/about"
              ><i class="fa fa-angle-right mr-2"></i>About Us</a>
            <a class="text-white" href="/contact"
              ><i class="fa fa-angle-right mr-2"></i>Contact Us</a>

              <a class="text-white" style={{marginTop:'2%'}} href="https://blog.ufuon.com/"
              ><i class="fa fa-angle-right mr-2"></i>Blog Page </a>
          </div>
        </div>
         
        
          <div class="col-lg-3 col-md-6 mb-5">
            <h3 class=" mb-4" style={{color:'#fff'}}>Contact us</h3>
            <b>Do you have questions or in need of further clarification? Speak to a Counsellor.</b>
             <img src="https://cdn4.iconfinder.com/data/icons/people-avatars-8/256/PEOPLE_ICON-21-512.png" style={{position:'relative',bottom:'10%'}} />
          </div>

          <div class="col-lg-3 col-md-6 mb-5">
          <h3 class="text-primary mb-4">Get In Touch</h3>
          <div class="d-flex">
            <h4 class="fa fa-map-marker-alt text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Address</h5>
              <p>59C Old Aba Road, Rumuobiakani, Port Harcort, Rivers State Nigeria.</p>
            </div>
          </div>
          <div class="d-flex">
            <h4 class="fa fa-envelope text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Email</h5>
              <p>hello.ufuon.com@gmail.com</p>
            </div>
          </div>
          <div class="d-flex">
            <h4 class="fa fa-phone-alt text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Phone</h5>
              <p>+234 8139 582 152</p>
            </div>
          </div>
        </div>
        </div>
        <div
          class="container-fluid pt-5"
          style={{borderTop: "1px solid rgba(23, 162, 184, 0.2)"}}
        >
          <p class="m-0 text-center text-white">
            &copy;
            <a class="font-weight-bold" style={{color:'white'}} href="#">Ufuon</a>.
            All Rights Reserved.
  
          {
              //<!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
          }
            Designed by 
            <a class=" font-weight-bold"style={{color:"white", marginLeft:'5px'}} href="https://codepally.com"
              >Codepally</a>
            
           
        
          </p>
        </div>
      </div>
      {
      // <!-- Footer End -->
      }
   
   </div>
  );
}
