import React, {useState,useEffect} from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { ProfileData } from './components/ProfileData';
import  Landing from './pages/landing/landing.js'
import About from './pages/about/about'
import Contact from './pages/contact/contact'
import Reset from './pages/reset/reset'
import SignIn from './pages/signin/singin'
import  Dashboard from './pages/dashboard/dashboard.js';
import Profile from './pages/profile/profile'
import Uploader  from './pages/upload/upload';
import { Routes, Route, Link ,  Navigate,} from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from './components/SignInButton';
import Terms from './pages/terms/terms'
import Learning from './pages/learning/learning'
import Registration from './pages/registration/registration'


/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [auth,setAuth]= useState(false)


    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                console.log("response---------->",response)
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            });
    }

    return (
      
                <div>
                    <h5 className="card-title">Welcome {accounts[0].name}</h5>
                    {graphData ? (
                        <ProfileData graphData={graphData} />
                    ) : (
                        <Button variant="secondary" onClick={RequestProfileData}>
                            Request Profile Information
                        </Button>
                    )}
                </div>

          
                
    
   
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
       

        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </div>
        
    );
};



export default function App() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [auth,setAuth]= useState(false)



  useEffect(() => {
    // wrap your async call here
    const loadData = async () => {
        console.log('data--accounts------------------>',instance,isAuthenticated)
    };

    // then call it here
    loadData();
  }, []);


        
        return (
            <>
                <PageLayout>
                    <Routes>
                        <Route index element={<Landing  />} />
                       
                        <Route path='about' element={<About  />} />
                        
                        <Route path='contact' element={<Contact  />} /> 
          
                        <Route path='resetpassword/:id' element={<Reset   />} /> 
                        
                        <Route path='terms' element={<Terms   />} /> 

                        <Route path='learning' element={<Learning   />} /> 
                    
                        <Route path='registration' element={<Registration   />} /> 
                        
                        
                
                        <Route path="*" element={<Landing  />} />
                    </Routes>
                </PageLayout>
             
        
              
            </>
        
    );
}
