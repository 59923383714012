import React from "react";
import './navber.css'
export const SignOutNav = () => {
    return (
           
                    <nav
                    class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5 navbar-custom">
                    <a
                        href="/"
                        class="navbar-brand font-weight-bold text-secondary "

                    >
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1665659075/UFUON%20LOGO%20PNG/ufuon_logo_2_alt9px.png" />
                         <b><span >fuon</span></b>
                    </a>
                    <div className="show-nomoblie">
                        <a href="https://code.ufuon.com/" class="button is-info is-light is-small"  style={{backgroundColor: "#000", color:'#fff'}}>Enter Code</a>
                        <a href="https://play.google.com/store/apps/details?id=com.ufuon.ufuonbeteV404239095383"  class="button is-info is-light is-small"  style={{backgroundColor: "#106B03", color:'#fff'}}>Download</a>
                    </div>
                      
                    <button
                        type="button"
                        class="navbar-toggler moblie-side "
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                        
                    >
                        <span class="navbar-toggler-icon "></span>
                    </button>
                    <div
                        class="collapse navbar-collapse justify-content-between"
                        id="navbarCollapse"
                    >
                        <div class="navbar-nav font-weight-bold mx-auto py-0">
                        
                                    
                        <a href="/" class="nav-item nav-link">Home</a>
                        <a href="https://store.ufuon.com" target={'_blank'} class="nav-item nav-link"> Ufuon store</a>
                        <a href="/terms" class="nav-item nav-link">Terms</a>
                        <a href="/about" class="nav-item nav-link">About</a>
                        <a href="/contact" class="nav-item nav-link">Contact</a>
        
                        </div>
                        <div className="NavMobile-left">
                            <a href="https://code.ufuon.com/" class="btn btn-primary px-4"  style={{backgroundColor: "#e83e8c"}}>Enter Code</a>
                        
                            <a href="https://play.google.com/store/apps/details?id=com.ufuon.ufuonbeteV404239095383" class="btn btn-primary px-4 "  style={{backgroundColor: "#000"}}>Download</a>
                        </div>
          
                    </div>
         </nav>
                   
            
    )

}