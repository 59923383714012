import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Slider from "../../components/caro/Slide";
import Images from "../../components/caro/image";
import './landing.css'
// import Page from '../page';

// }

export default function Landing() {

//   const post = useLoaderData();

  return (
    <div>


    {
      //     <!-- Header Start -->
    }
    <div class="container-fluid  px-0 px-md-5 " style={{backgroundColor: "#46178F",paddingTop: "5%"}}>
      <div class="row align-items-center px-3 bannar-side-work">
        <div class="col-lg-6 text-center text-lg-left">
          <h4 class="text-white mb-4 mt-5 mt-lg-3 landing-h4-constom">A New Approach to Education.</h4>
          <h6 class="display-3 font-weight-bold text-white landing-h6-constom" style={{fontSize:'10px !important'}}>
          Learning Center For Kids and Students.
          </h6>
          <p class="text-white mb-4">
            Education is  fun and very essential to everyone, 
           Boost knowledge and performance by learning on UFUON 
             </p>
          <div style={{display:'flex',textAlign:'center'}}>
               <a href="https://play.google.com/store/apps/details?id=com.ufuon.ufuonbeteV404239095383" className='costom-image-divice1'  ><img src="https://res.cloudinary.com/codepally/image/upload/v1668428794/ridora%20website/googleplay_pshfgp.jpg"  /></a>
              <a href="#" className='costom-image-divice' style={{position:'relative',right:'10%'}}  ><img src="https://res.cloudinary.com/codepally/image/upload/v1668428783/ridora%20website/appstore_lckndg.jpg"  /></a>

          </div>
         
        </div>
        <div class="col-lg-6 text-center text-lg-right">
          <img class="img-fluid mt-5" src="https://res.cloudinary.com/codepally/image/upload/v1686061948/UFUON%20LOGO%20PNG/New_landing_page_sjb9df.png" alt="" />
        </div>
      </div>
    </div>
    {
          // <!-- Header End -->
    }

    { 
      //  <!-- Study smart  -->
    }

    <div class="container-fluid py-5">
          <div class="container">
          <div class="columns scta-body">
                  <div class="column">
                     <div class="scta-red  scta-sub">
                         <img width="32px" height="32px" src="https://kahoot.com/files/2020/05/kahoot-for-schools-icon.png"/>
                         <h6 style={{float:'right',position:'relative',right:'15%'}}>&nbsp;Ufuon! at school</h6>
                         <p class="m-0">Engaging group and distance learning for teachers and students.</p>
                    
                     </div>
                  </div>
                  <div class="column">
                        <div class="scta-blue scta-sub">
                          <img width="32px" height="32px" src="https://kahoot.com/files/2020/05/kahoot-at-work-icon.png"/>
                          <h6 style={{float:'right',position:'relative',right:'20%'}}>&nbsp;Ufuon! at work</h6>
                          <p class="m-0">EDeliver training, presentations, meetings and events in-person and more.</p>
                       </div>
                  </div>
                  <div class="column">
                          <div class="scta-green scta-sub">
                          <img width="32px" height="32px" src="https://kahoot.com/files/2020/05/kahoot-at-home-icon.png"/>
                          <h6 style={{float:'right',position:'relative',right:'20%'}}>&nbsp;Ufuon! at home</h6>
                          <p class="m-0">Learning Apps for book reading ,games and fun for  family  at  home study.</p>
                      </div>
                  </div>
                  <div class="column">
                    <div class="scta-yellow scta-sub">
                        <img width="32px" height="32px" src="https://kahoot.com/files/2020/07/academy-box-icon.png"/>
                        <h6 style={{float:'right',position:'relative',right:'20%'}}>&nbsp;Ufuon! Academy</h6>
                        <p class="m-0">Explore content and join one of the world’s largest educator communities.</p>
                    </div>
                  </div>
                </div>
          </div>
    </div>
    { 
      //  <!-- Study smart  -->
    }
    
    <div class="container-fluid bg-light "  style={{borderRadius:'10% 10% 10px 10px',marginBottom:'5%'}} >
        <div class="container" style={{paddingTop:'7%',}}>
          <div class="coustom-container">
                  <h1 class="mb-4" style={{ textAlign:'center' }}>Study smart, play hard 🧠 </h1>
                  <h5 class="mb-4" style={{ textAlign:'center' }}>Stop spending hours re-reading and re-copying your notes! </h5>
                  <div class="columns coustom-colum">
                  <div class="column">
                    <h3 class="h3-coustom" style={{textAlign: "left"}}>
                      <img decoding="async" loading="lazy" src="https://kahoot.com/files/2022/07/01-300x300.png" alt="" width="52" height="52" class="wp-image-97822 alignleft"/>
                      Study smart on the go</h3>
                      <p style={{position:'relative',bottom:'10px'}}>
                     Just hit play to start studying and mastering topics wherever you are.
                      </p>
                  </div>
                  <div class="column">
                  <h3 class="h3-coustom" style={{textAlign: "left"}}>
                    <img decoding="async" loading="lazy" src="https://kahoot.com/files/2022/07/02-300x300.png" alt="" width="52" height="52" class="wp-image-97822 alignleft"/>
                      Socialize your
                      study </h3>
                      <p style={{position:'relative',bottom:'10px'}}>
                      Challenge your friends with study groups to fight for the top spot on the podium.
                      </p>
                  </div>
                  <div class="column">
                  <h3 class="h3-coustom" style={{textAlign: "left"}}>
                    <img decoding="async" loading="lazy" src="https://kahoot.com/files/2022/07/03-300x300.png" alt="" width="52" height="52" class="wp-image-97822 alignleft"/>
                     Quiz  presentations </h3>
                      <p style={{position:'relative',bottom:'10px'}}>
                      Create presentations in a few clicks and turn your class into a game show.
                      </p>
                  </div>
                  <div class="column">
                  <h3 class="h3-coustom" style={{textAlign: "left"}}>
                    <img decoding="async" loading="lazy" src="https://kahoot.com/files/2022/07/04-300x300.png" alt="" width="52" height="52" class="wp-image-97822 alignleft"/>
                    Boost learning
retention </h3>
                      <p style={{position:'relative',bottom:'10px'}}>
                      Make Ufuon, not notes. Ufuons ask you questions, your notes won’t.
                      </p>
                  </div>
                  </div>

          </div>
    </div>
   
   
  </div>

  <div class="container-fluid  "   >
      <div class="row" style={{paddingBottom:'1%'}}>
      <div class="col-lg-4 col-md-6 pb-1">
        <div
          class="d-flex bg-light shadow-sm border-top rounded mb-4"
          style={{padding: "30px"}}
        >
          <i
            class="flaticon-050-fence h1 font-weight-normal  mb-3"
            style={{color: "#dc3545"}}
          ></i>
          <div class="pl-4">
            <h4>MANAGEMENT</h4>
            <p class="m-0">
            Manage efficiently. 
            Manage the fees, attendance, assignments and various other details 
            in one place. Store everything easily and efficiently.....


            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 pb-1">
        <div
          class="d-flex bg-light shadow-sm border-top rounded mb-4"
          style={{padding: "30px"}}
        >
          <i
            class="flaticon-017-toy-car h1 font-weight-normal  mb-3"
            style={{color: "#6610f2"}}
          ></i>
          <div class="pl-4">
            <h4>SET QUIZ FOR YOUR STUDENT</h4>
            <p class="m-0">
            Easily set quizzes for students to help them 
            test and practice what they have been learning 
            in school....
            </p> 
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 pb-1">
      <div
        class="d-flex bg-light shadow-sm border-top rounded mb-4"
        style={{padding: "30px"}}
      >
      
        <i
        class="flaticon-030-crayons h1 font-weight-normal text-primary mb-3"
      ></i>
        <div class="pl-3">
          <h4> CREATE and PLAY</h4>
          <p class="m-0">
          Make your own learning content on Ufuon! Create or choose from ready-to-play 
          Ufuon contents and games  created by Verified creators and content 
            
          </p>
        </div>
      </div>
    </div>

    </div>
  </div>
  

   { 
    //  <!-- About Start -->
    }
    <div class="container-fluid py-5" style={{background:'rgba(227,236,252,.5)',paddingTop:'1%',borderRadius:'15% 0% 30% 0'}}>
    
      <div class="container">
      <h1 style={{color: "#000",textAlign:'center',marginTop:'4%',marginBottom:'6%'}} >   Ufuon Makes Tutoring Much Easier </h1>
        <div class="row align-items-center">
          <div class="col-lg-4">
            <img
              class="img-fluid rounded mb-5 mb-lg-0"
              src="https://res.cloudinary.com/codepally/image/upload/v1686142679/UFUON%20LOGO%20PNG/WEB_IMAGE.svg_fdlnp1.png"
              alt=""
            />
          </div>
          <div class="col-lg-8">
       
            <h1 class="mb-4">Get Qualified Lesson Teacher For Your Kids</h1>
            <p>
          We Make life easy for everyone by showcasing qualified teachers to schools and parents for home lesson for your kids 

            </p>
            <div class="row pt-2 pb-4">
              <div class="col-6 col-md-4">
                <img class="img-fluid rounded" src="https://res.cloudinary.com/codepally/image/upload/v1675168751/UFUON%20LOGO%20PNG/new_image_befht5.png" alt="" />
              </div>
              <div class="col-6 col-md-8">
                <ul class="list-inline m-0">
                  <li class="py-2 border-top border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Teaching and Learning Made easy
                  </li>
                  <li class="py-2 border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Have Access to all your Educative Material in one place.
                  </li>
                  <li class="py-2 border-bottom">
                    <i class="fa fa-check text-primary mr-3"></i>Mobile Class, access your School Material/Lesson from anywhere.
                  </li>
                </ul>
              </div>
            </div>
           
          </div>
          
        </div>
        
      </div>
    </div>

  
  


 
    {
      // <!-- Registration Start -->
    }
    <div class="container-fluid py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 mb-5 mb-lg-0">
            <p class="section-title pr-5">
              <span class="pr-2">Books and other Educative Material</span>
            </p>
            <h1 class="mb-4">Educative  Books For Your Kid</h1>
            <p>
              Explore the world of our Rich and Educative  Books for kids,
            </p>
            <ul class="list-inline m-0">
              <li class="py-2">
                <i class="fa fa-check text-success mr-3"></i>Access  Books and other super hero Characters.
              </li>
              <li class="py-2">
                <i class="fa fa-check text-success mr-3"></i>Access to quizzes and other fun Material uploaded by Teachers.
                </li>
              <li class="py-2">
                <i class="fa fa-check text-success mr-3"></i>School Assessment made easy.
              </li>
            </ul>
            <a href="" class="btn btn-primary mt-4 py-2 px-4">Download Now</a>
          </div>
          <div class="col-lg-5">
            <div class="card border-0">
              <img
              class="img-fluid rounded mb-5 mb-lg-0"
              src="https://res.cloudinary.com/codepally/image/upload/v1675168799/UFUON%20LOGO%20PNG/web_image_edited_mq3qzj.png"
              width="68%"
              alt=""
            />
            </div>
          </div>
        </div>
        
      </div>
    </div>



    {
     //<!-- Why me-->
    }

    <div class="container-fluid py-5" style={{background:'#dcfce7',paddingTop:'1%',borderRadius:'15% 0% 30% 0',}}>
    
    <div class="container" style={{paddingBottom:'0%'}}>
       <h1 style={{color: "#000",textAlign:'center',marginTop:'4%',marginBottom:'6%'}} >Ufuon Makes Tutoring More Easy   </h1>
          <div class="row">
             <div class="col-lg-4 col-md-6 pb-1">
              <div
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{padding: "20px"}}
                  >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal  mb-3"
                      style={{color: "#dc3545"}}
                    ></i>
                    <div class="pl-1 landing-Cheduling">
                       <img src="https://res.cloudinary.com/codepally/image/upload/v1686140703/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/book_a_lesson_dqtjxj.png" />
                        <h6>Create Lesson add quizs ..</h6>
                      <p class="m-0">
                         Manage  assignments  creating lessons,  and quizzes for kids in your comfort and
                        easily and efficiently.....
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                   <div
                      class="d-flex bg-light shadow-sm border-top rounded mb-4"
                      style={{padding: "20px"}}
                    >
                      <i
                        class="flaticon-050-fence h1 font-weight-normal  mb-3"
                        style={{color: "#dc3545"}}
                      ></i>
                      <div class="pl-1 landing-Cheduling">
                         <img src="https://res.cloudinary.com/codepally/image/upload/v1686140701/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/reschedule_mh5axc.png" />
                          <h6>Schedule lesson or class</h6>
                        <p class="m-0">
                     
                        Scheduling lessons for both kids and tutors is very easy with just a few clicks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 pb-1">
                  <div
                     class="d-flex bg-light shadow-sm border-top rounded mb-4"
                     style={{padding: "20px"}}
                   >
                     <i
                       class="flaticon-050-fence h1 font-weight-normal  mb-3"
                       style={{color: "#dc3545"}}
                     ></i>
                     <div class="pl-1 landing-createclass">
                        <img src="https://res.cloudinary.com/codepally/image/upload/v1686146157/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/tutor_replacement_jaiaf6.png" />
                         <h6>Get  tutor for lesson</h6>
                       <p class="m-0">
                         You can easily find a suitable tutor for your kids in the shortest possible time
                        and ways your kid learn ..
                       </p>
                     </div>
                   </div>
                 </div>
          </div>
          <div class="row">
          <div class="col-lg-4 col-md-6 pb-1">
           <div
                 class="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "20px"}}
               >
                 <i
                   class="flaticon-050-fence h1 font-weight-normal  mb-3"
                   style={{color: "#dc3545"}}
                 ></i>
                 <div class="pl-1 landing-create-control">
                    <img src="https://res.cloudinary.com/codepally/image/upload/v1686140702/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/quality_control_ner3y5.png" />
                     <h6>Quality control ...</h6>
                   <p class="m-0">
                  Tutors partners on our platform are verified, checked and have gone through a selective process 
   
                   </p>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 col-md-6 pb-1">
                <div
                   class="d-flex bg-light shadow-sm border-top rounded mb-4"
                   style={{padding: "20px"}}
                 >
                   <i
                     class="flaticon-050-fence h1 font-weight-normal  mb-3"
                     style={{color: "#dc3545"}}
                   ></i>
                   <div class="pl-1 landing-create-Payment">
                      <img src="https://res.cloudinary.com/codepally/image/upload/v1686140703/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/payment_fneqtt.png" />
                       <h6>Payment</h6>
                     <p class="m-0">
                        Payment and payment details on this platform are safe and all major payment methods are accepted.
                     </p>
                   </div>
                 </div>
               </div>
               <div class="col-lg-4 col-md-6 pb-1">
               <div
                  class="d-flex bg-light shadow-sm border-top rounded mb-4"
                  style={{padding: "20px"}}
                >
                  <i
                    class="flaticon-050-fence h1 font-weight-normal  mb-3"
                    style={{color: "#dc3545"}}
                  ></i>
                  <div class="pl-1 landing-create-security">
                     <img src="https://res.cloudinary.com/codepally/image/upload/v1686140702/UFUON%20LOGO%20PNG/UFUON%20TUTORING%20WEB%20ICONS/security_lw5pal.png" />
                      <h6>Data security</h6>
                    <p class="m-0">
                    All data on the app is safe for children and all your personal data is 100% secure with us.

                    </p>
                  </div>
                </div>
              </div>
       </div>
       <h3 style={{color: "#000",textAlign:'center',marginTop:'4%',marginBottom:'2%'}} >See what other parents are saying feedback   </h3>
    </div>
  </div>

  <Slider images={Images} />

   { 
    //  <!-- Testimonial Start -->
    }
  
     {
      //  <!-- Testimonial End -->
      }

      {
    //<!-- Footer Start -->
      }
     
      <div
        class="container-fluid text-white mt-5 py-5 px-sm-3 px-md-5"
        style={{backgroundColor:"#46178F"}}
      >
        <div class="row pt-5">
          <div class="col-lg-3 col-md-6 mb-5">
            <a  href="" class="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
              style={{fontSize: "40px", lineHeight: "40px"}}
            >
              <i class="flaticon-043-teddy-bear"></i>
              <span class="text-white">ABOUT US</span>
            </a>
            <p style={{fontSize:"12px"}}>
            Ufuon is a game based learning platform that helps students
             and teachers be the best they can be. For teachers, ufuon 
             helps connect them to thousand of students who can enjoy 
             the learning content they create that  they might not have 
             access to in a  traditional classroom.  
             For students, ufuon provide an interesting way for them to learn, 
             learning on ufuon is optimized to mimic game playing, to keep the 
             interest of the students longer than a class based learning.
            </p>
            <div class="d-flex justify-content-start mt-4">
            <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0"
            style={{width: "38px", height: "38px"}} target="_blank"
            href="https://x.com/ufuon_edu"
            ><i class="fab fa-twitter"></i></a>
              <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="https://www.facebook.com/Ufuon1-111894188419377"
                  ><i class="fab fa-facebook-f"></i
              ></a>
              <a  class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="#"
                  ><i class="fab fa-linkedin-in"></i
              ></a>
              <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                  style={{width: "38px", height: "38px"}}
                  href="https://www.instagram.com/ufuon_edu/"
                  ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-5"  style={{paddingTop:'1%'}}>
          <h3   class=" mb-4" style={{marginLeft:'10%',color:'#fff'}}>Quick Links</h3>
          <div  class="d-flex flex-column justify-content-start" style={{marginLeft:'15%'}}>
            <a class="text-white mb-2" href="/"
              ><i class="fa fa-angle-right mr-2"></i>Home</a>
            
              <a href="https://store.ufuon.com" target={'_blank'} class="text-white mb-2"> <i class="fa fa-angle-right mr-2"></i> Ufuon store</a>

            <a class="text-white mb-2" href="/about"
              ><i class="fa fa-angle-right mr-2"></i>About Us</a>
            <a class="text-white" href="/contact"
              ><i class="fa fa-angle-right mr-2"></i>Contact Us</a>

              <a class="text-white" style={{marginTop:'2%'}} href="https://blog.ufuon.com/"
              ><i class="fa fa-angle-right mr-2"></i>Blog Page </a>
          </div>
        </div>
         
        
          <div class="col-lg-3 col-md-6 mb-5">
            <h3 class=" mb-4" style={{color:'#fff'}}>Contact us</h3>
            <b>Do you have questions or in need of further clarification? Speak to a Counsellor.</b>
             <img src="https://cdn4.iconfinder.com/data/icons/people-avatars-8/256/PEOPLE_ICON-21-512.png" style={{position:'relative',bottom:'10%'}} />
          </div>

          <div class="col-lg-3 col-md-6 mb-5">
          <h3 class="text-primary mb-4">Get In Touch</h3>
          <div class="d-flex">
            <h4 class="fa fa-map-marker-alt text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Address</h5>
              <p>59C Old Aba Road, Rumuobiakani, Port Harcort, Rivers State Nigeria.</p>
            </div>
          </div>
          <div class="d-flex">
            <h4 class="fa fa-envelope text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Email</h5>
              <p>hello.ufuon.com@gmail.com</p>
            </div>
          </div>
          <div class="d-flex">
            <h4 class="fa fa-phone-alt text-primary"></h4>
            <div class="pl-3">
              <h5 class="text-white">Phone</h5>
              <p>+234 8139 582 152</p>
            </div>
          </div>
        </div>
        </div>
        <div
          class="container-fluid pt-5"
          style={{borderTop: "1px solid rgba(23, 162, 184, 0.2)"}}
        >
          <p class="m-0 text-center text-white">
            &copy;
            <a class="font-weight-bold" style={{color:'white'}} href="#">Ufuon</a>.
            All Rights Reserved.
  
          {
              //<!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
          }
            Designed by 
            <a class=" font-weight-bold"style={{color:"white", marginLeft:'5px'}} href="https://codepally.com"
              >Codepally</a>
            
           
        
          </p>
        </div>
      </div>
     
   </div>
  );
}
